import { AfterContentChecked, Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

/**
 * This directives add a bottom fade event to an element if it has scroll.
 * It depends on an existent global class 'app__fade' that will add the effect.
 */
@Directive({
  selector: '[fadeScrollElement]',
})
export class FadeScrollElementDirective implements OnInit, AfterContentChecked {
  divElement: HTMLDivElement;

  private fadeClass = 'app__fade';

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.createFadeDivElement();
  }

  ngAfterContentChecked() {
    if (this.element.nativeElement.scrollHeight === this.element.nativeElement.clientHeight) {
      this.renderer.removeClass(this.divElement, this.fadeClass);
    }
  }

  @HostListener('scroll', [])
  onWindowScroll() {
    const elementScrollTop = this.element.nativeElement.scrollTop;
    const elementPosition = this.element.nativeElement.scrollHeight - this.element.nativeElement.clientHeight;
    const maxScroll = elementScrollTop / elementPosition;
    if (maxScroll >= 1) {
      this.renderer.removeClass(this.divElement, this.fadeClass);
    } else {
      this.renderer.addClass(this.divElement, this.fadeClass);
    }
  }

  private createFadeDivElement() {
    this.divElement = this.renderer.createElement('div');
    this.renderer.addClass(this.divElement, this.fadeClass);
    const parentElement = this.element.nativeElement.parentElement;
    this.renderer.appendChild(parentElement, this.divElement);
  }
}
