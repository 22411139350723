<div class="profile-section">

    <subscription-management></subscription-management>

    <div class="mb-3">
        <h1 translate >{{'settings.preferences'}}</h1>
    </div>

    <div class="columns">
        <div *ngFor="let f of forms; let i = index" 
            class="peferenceFormTab"
            (click)="selectForm(i)"
            >
            <label [ngClass]="{'selected': f == form}">#{{i}}</label>
        </div>
        <div class="peferenceFormTab"
              (click)="createPreferences()">
            <label>+</label>
        </div>
    </div>

    <form [formGroup]="form"  (ngSubmit)="updatePreferences()">
        <div class="columns">
            <div class="column profileForm box">
                <label class="profileLabel" translate>{{'settings.personalData'}}</label>
                
                <div class="field">
                    <label class="label" translate>{{'settings.fullname'}}</label>
                    <div class="control">
                        <input class="input" type="text" formControlName="nameOrganization" value=""/>
                    </div>
                </div>

                <div class="field">
                    <label class="label" translate>{{'settings.position'}}</label>
                    <div class="control">
                        <input class="input" type="text" formControlName="position"  value=""/>
                    </div>
                </div>

                <div class="field">
                    <label class="label" translate>{{'settings.identityNumber'}}</label>
                    <div class="control">
                        <input class="input" type="text" formControlName="identificationNumber"  value=""/>
                    </div>
                </div>

                <div class="field">
                    <label class="label" translate>{{'settings.memberNumber'}}</label>
                    <div class="control">
                        <input class="input" type="text" formControlName="memberNumber"  value=""/>
                    </div>
                </div>

            </div>

            <div class="column profileForm box">
                <label class="profileLabel" translate>{{'settings.contactDetails.title'}}</label>

                <div class="field">
                    <label class="label" translate>{{'settings.contactDetails.email'}}</label>
                    <div class="control">
                        <input class="input" type="text" formControlName="email" value=""/>
                    </div>
                </div>

                <div class="field">
                    <label class="label" translate>{{'settings.contactDetails.phone'}}</label>
                    <div class="control">
                        <input class="input" type="text" formControlName="phoneNumber" value=""/>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="columns">
            <div class="column profileForm box">
                <label class="profileLabel" translate>{{'settings.templateConfiguration.title'}}</label>
                <div class="columns">
                    <div class="column">

                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.lang'}}</label>
                            <div class="control">
                                <select name="language-picker-select" formControlName="lang" >
                                    <option value="es">Español</option>
                                    <option value="en" selected>English</option>
                                    <option value="nl">Dutch</option>
                                    <!-- other language options -->
                                  </select>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.typographyUrl'}}</label>
                            <div class="control">
                                <input class="input" type="text" formControlName="typographyUrl" value=""/>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.typographyName'}}</label>
                            <div class="control">
                                <input class="input" type="text" formControlName="typographyName" value=""/>
                            </div>
                        </div>
                        
                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.fontSize'}}</label>
                            <div class="control">
                                <input class="input" type="text" formControlName="typographySize" value="9"/>
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.fontColor'}}</label>
                            <div class="control">
                                <input class="input" type="color" formControlName="typographyColor" value="#000000" style="max-width: 80px;"/>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.coverPictureURL'}}</label>
                            <div class="control">
                                <input class="input" type="text" formControlName="coverBgUrl" value=""/>
                            </div>
                            <p class="help is-link" translate>{{'settings.templateConfiguration.coverPictureURLTip'}}</p>
                        </div>

                        <div class="field">
                            <label class="label" translate>{{'settings.templateConfiguration.insigniaURL'}}</label>
                            <div class="control">
                                <input class="input" type="text" formControlName="marginLogoUrl" value=""/>
                            </div>
                            <p class="help is-link" translate>{{'settings.templateConfiguration.insigniaURLTip'}}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="is-fullwidth floating-button" style="display:flex; justify-content: flex-end">
            <button *ngIf="forms.length > 1" class="button is-rounded is-block is-normal is-danger mr-2" type="button" (click)="deletePreferences()"  [disabled]="form.invalid" translate>{{'form.delete'}}</button>
            <button class="button is-rounded is-block is-normal is-primary" [ngClass]="{'is-loading': isSaving === true }" type="submit" [disabled]="form.invalid" translate>{{'form.save'}}</button>
        </div>
    </form>
</div> 