import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StateFacade } from '../../store/state.facade';

import { getProjectsAndDocuments, sectionsInDocument, optionsInSection, userDocument, recentDocuments, treeDocument } from '../../store/store';
import { TemplateState } from './templates.reducer';
import { CreateDocument, CreateOption, CreateProject, CreateSection, CreateUserDocument, DeleteDocument, DeleteOption, DeleteProject, DeleteSection, DeleteUserDocument, Flush, LoadOptionsInSection, LoadOptionsInSectionFetched, LoadProjectsAndDocuments, LoadRecentDocument, LoadRecentDocuments, LoadSectionsInDocument, LoadTreeDocument, ModifyUserDocument, PatchDocument, PatchOption, PatchProject, PatchSection, TemplatesActionsType } from './templates.actions';
import { ProjectDocuments, Project, Document, Section, Option, UserDocument, SectionTree } from './templates.model';
import { TemplateService } from './templates.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesFacade extends StateFacade {

  constructor(public actions: Actions, private readonly templateStore: Store<TemplateState>, private readonly templateService: TemplateService) {
    super(actions, templateStore);
  }

  flushState(): void {
    this.templateStore.dispatch(new Flush());
  }

  projectsAnDocuments(): void {
    this.templateStore.dispatch(new LoadProjectsAndDocuments());
  }

  get getProjectsAnDocuments(): Observable<Array<ProjectDocuments>> {
    return this.store.pipe(select(getProjectsAndDocuments));
  }

  loadTree(projectId: number, documentId: number): void {
    this.templateStore.dispatch(new LoadTreeDocument(projectId, documentId));
  }

  get treeDocument(): Observable<Array<SectionTree>> {
    return this.store.pipe(select(treeDocument));
  }

  loadSections(projectId: number, documentId: number): void {
    this.templateStore.dispatch(new LoadSectionsInDocument(projectId, documentId));
  }

  get sectionsInDocument(): Observable<Array<Section>> {
    return this.store.pipe(select(sectionsInDocument));
  }

  loadOptions(documentId: number, sectionId: number): void {
    return this.templateStore.dispatch(new LoadOptionsInSection(documentId, sectionId));
  }

  loadOptionsFromTree( sectionId: number, options: Array<Option>) {
    return this.templateStore.dispatch(new LoadOptionsInSectionFetched(sectionId, options) )
  }

  get optionsInSection(): Observable<Array<Option>> {
    return this.store.pipe(select(optionsInSection));
  }

  // UserDocument 
  loadRecentDocuments(userId: string) {
    return this.templateStore.dispatch(new LoadRecentDocuments(userId));
  }
  createUserDocument(userId: string, userDocument:UserDocument){
    return this.templateStore.dispatch(new CreateUserDocument(userId, userDocument));
  }

  modifyUserDocument(userId: string, userDocument:UserDocument) {
    return this.templateStore.dispatch(new ModifyUserDocument(userId, userDocument));
  }

  deleteUserDocument(userId: string, userDocumentId:number) {
    return this.templateStore.dispatch(new DeleteUserDocument(userId, userDocumentId));
  }

  loadUserDocument(userId: string, userDocumentId:number) {
    return this.templateStore.dispatch(new LoadRecentDocument(userId, userDocumentId));
  }

  get userDocument(): Observable<UserDocument> {
    return this.store.pipe(select(userDocument));
  }

  get recentDocuments(): Observable<Array<UserDocument>> {
    return this.store.pipe(select(recentDocuments));
  }

  // Create
  createProject(project: Project) {
    return this.templateStore.dispatch(new CreateProject(project));
  }
  createDocument(projectId: number, document: Document) {
    return this.templateStore.dispatch(new CreateDocument(projectId, document));
  }
  createSection(projectId: number, documentId: number, section: Section) {
    return this.templateStore.dispatch(new CreateSection(projectId, documentId, section));
  }
  createOption(projectId: number, documentId: number, sectionId: number, option: Option) {
    return this.templateStore.dispatch(new CreateOption(projectId, documentId, sectionId, option));
  }

  // Modifies

  modifyProject(project: Project) {
    return this.templateStore.dispatch(new PatchProject(project));
  }
  modifyDocument(projectId: number, document: Document) {
    return this.templateStore.dispatch(new PatchDocument(projectId, document));
  }
  modifySection(projectId: number, documentId: number, section: Section) {
    return this.templateStore.dispatch(new PatchSection(projectId, documentId, section));
  }
  modifyOption(projectId: number, documentId: number, sectionId: number, patch: Option) {
    return this.templateStore.dispatch(new PatchOption(projectId, documentId, sectionId, patch));
  }

  // Delete
  deleteProject(projectId: number) {
    return this.templateStore.dispatch(new DeleteProject(projectId));
  }
  deleteDocument(projectId: number, documentid: number) {
    return this.templateStore.dispatch(new DeleteDocument(projectId, documentid));
  }
  deleteSection(projectId: number, documentId: number, sectionId: number) {
    return this.templateStore.dispatch(new DeleteSection(projectId, documentId, sectionId));
  }
  deleteOption(projectId: number, documentId: number, sectionId: number, optionId: number) {
    return this.templateStore.dispatch(new DeleteOption(projectId, documentId, sectionId, optionId));
  }

  // PDF

  generatePDFDocument(userId: string, userDocumentId: number) {
    return this.templateService.generatePDFDocument(userId, userDocumentId);
  }

  // Preferences
  loadPreferences(userId: string) {
    return this.templateService.loadPreferences(userId);
  }

  createPreferences(userId: string,preferences ) {
    return this.templateService.createPreferences(userId, preferences);
  }

  updatePreferences(userId: string, preferences) {
    return this.templateService.updatePreferences(userId, preferences);
  }

  deletePreferences(userId: string, preferenceId: string) {
    return this.templateService.deletePreferences(userId, preferenceId);
  }
}
