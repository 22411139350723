import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class PaymentRequiredInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(evt => {},
        err =>{
          console.error(err)
          if(err instanceof HttpErrorResponse) {
            if((err as HttpErrorResponse).status == 402) {
              this.router.navigate(['/plans'])
            }
          }
        })
    );
  }
}
