<div class="preference" [ngClass]="{'selected': isSelected}">
    <button type="button"><p>#{{idx}}</p></button>

    <div class="preference-details box" >

        <div class="is-flex is-flex-direction-column">

            <div class="is-flex">
                <label class="label">{{'settings.fullname' | translate}}:</label>
                <span class="ml-2">{{preference.nameOrganization}}</span>
            </div>
    
            <div class="is-flex">
                <label class="label">{{'settings.position' | translate}}:</label>
                <span class="ml-2">{{preference.position}}</span>
            </div>
        
            <div class="is-flex">
                <label class="label">{{'settings.identityNumber' | translate}}:</label>
                <span class="ml-2">{{preference.identificationNumber}}</span>
            </div>
        
            <div class="is-flex">
                <label class="label">{{'settings.memberNumber' | translate}}:</label>
                <span class="ml-2"> - </span>
            </div>
        
            <div class="is-flex">
                <label class="label">{{'settings.contactDetails.email' | translate}}:</label>
                <span class="ml-2">{{preference.email}}</span>
            </div>
        
            <div class="is-flex">
                <label class="label">{{'settings.contactDetails.phone' | translate}}:</label>
                <span class="ml-2">{{preference.phoneNumber}}</span>
            </div>

        </div>

        <div class="is-flex is-flex-direction-column">        
            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.lang' | translate}}:</label>
                <span class="ml-2">{{preference.lang}}</span>
            </div>
            <!-- Other Column -->
        
            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.typographyUrl' | translate}}:</label>
                <span class="ml-2">{{preference.typographyUrl}}</span>
            </div>
        
        
            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.typographyName' | translate}}:</label>
                <span class="ml-2">{{preference.typographyName}}</span>
            </div>
        
            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.fontSize' | translate}}:</label>
                <span class="ml-2">{{preference.typographySize}}</span>
            </div>
            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.fontColor' | translate}}:</label>
                <span class="ml-2">{{preference.typographyColor}}</span>
            </div>

            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.coverPictureURL' | translate}}:</label>
                <span class="ml-2">{{preference.coverBgUrl}}</span>
            </div>
        
            <div class="is-flex">
                <label class="label">{{'settings.templateConfiguration.insigniaURL' | translate}}:</label>
                <span class="ml-2">{{preference.marginLogoUrl}}</span>
            </div>
        </div>
    </div>

</div>
