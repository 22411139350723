import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ORGANIZATION_ID, SUBSCRIPTION_SERVICE_BASE_URL } from 'src/app/config';
import { Plan, Subscriber, Subscription } from './subscription.model';

@Injectable({
    providedIn: 'root',
  })
  export class SubscriptionService {
  
    constructor(private http: HttpClient) {}

    getPlans() {
        return this.http.get<Array<Plan>>(SUBSCRIPTION_SERVICE_BASE_URL+ `/plans?orgId=${ORGANIZATION_ID}`);
    }

    subscribe(planId: number) {
        const headers = new HttpHeaders();
        return this.http.post(SUBSCRIPTION_SERVICE_BASE_URL+ `/subscriptions`, {
            planId,
            method: "creditcard"
        },
        {headers, responseType: 'text'});
 
    }

    querySubscriptions() {
        return this.http.get<Array<Subscription>>(SUBSCRIPTION_SERVICE_BASE_URL+ `/subscriptions`);
    }

    querySubscribersOfSubscription(subscriptionId: string) {
        return this.http.get<Array<Subscriber>>(SUBSCRIPTION_SERVICE_BASE_URL+ `/subscriptions/${subscriptionId}/subscribers`);
    }

    addSubscriberToSubscription(subscriptionId: string, subscriberId: string) {
        return this.http.put<Subscriber>(SUBSCRIPTION_SERVICE_BASE_URL+ `/subscriptions/${subscriptionId}/subscribers/${subscriberId}`, {});
    }

    updateStateOfSubscriber(subscriptionId: string, subscriberId: string, isActive: boolean) {
        return this.http.patch<Subscriber>(SUBSCRIPTION_SERVICE_BASE_URL+ `/subscriptions/${subscriptionId}/subscribers/${subscriberId}`, 
        {
            status: isActive
        });

    }
}