<div class="hero is-fullheight is-background">
<div class="hero-body">
    <div class="container has-text-centered">
      <div class="column is-offset-2" style="margin: auto; max-width: 500px;">
        
        <div class="box has-background-danger ">
          <h3 class="title title-montserrat-white has-text-white" translate>{{'navigation.deleteAccount'}}</h3>
          <p class="subtitle subtitle-montserrat has-text-white mt-3 px-3" translate>
            {{'deleteAccount.disclaimer'}}
          </p>

            <form [formGroup]="form" (ngSubmit)="forgetMe()" >
                <div class="field">
                    <div class="control">
                    <input class="input is-large has-text-white has-background-danger" type="email" placeholder="{{'form.email' |translate}}" formControlName="email" autofocus="">
                    </div>
                </div>
                <div *ngIf="form.controls.username?.invalid && (form.controls.username?.dirty || form.controls.username?.touched)" class="message is-danger">
                  <div class="message-body" translate><strong>{{'form.error' | translate}}:</strong> {{'form.wrongEmail'}}</div>
                </div>
                 <button class="button mt-3 is-block has-text-danger has-background-white is-large is-fullwidth"
                    type="submit"
                    [disabled]="form.invalid"
                    translate
                    >
                    <strong translate>{{'navigation.deleteAccount'}}</strong>
                </button>
            </form>
        </div>

        <p class="has-text-white">
            <a routerLink="/login" translate>{{'login.logIn'}}</a> &nbsp;·&nbsp;
            <a routerLink="/signup" translate>{{'signup.signUp'}}</a> &nbsp;·&nbsp;
            <a routerLink="/forgot-password" translate>{{'navigation.recoverPassword'}}</a> &nbsp;·&nbsp;
        </p>

      </div>
    </div>
  </div>
</div>