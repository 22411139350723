import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class Modal implements OnChanges {
    @ViewChild('modal') editModal: ElementRef;
    @Input() public title: string;
    @Input() public showModal: boolean = false;
    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.showModal && !changes.showModal.firstChange) {
            this.showModal = changes.showModal.currentValue;
            if(this.showModal) {
                this.showEditElementModal()
            } else {
                this.hideEditElementModal()
            }
          }
    }

    showEditElementModal() {
        this.editModal.nativeElement.classList.add('is-active');
    }

    hideEditElementModal() {
        this.editModal.nativeElement.classList.remove('is-active');
        this.modalClosed.emit();
    }
    
}