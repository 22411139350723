import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteInputComponent implements ControlValueAccessor{
  @ViewChild('containerSelected', { read: ViewContainerRef }) containerSelected;

  /**
   * Emits the option selected on click
   */
  @Output() optionClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() addNewClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  /**
   * Placeholder value for the input
   */
   @Input() placeholder: string;
   @Input() placeholderTranslationTag: string;

  /**
   * Message to show if the search brings no results
   */
  @Input() notFoundPlaceholder: string;

  /**
   * Option to display or not the add button in the dropdown
   */
  @Input() displayAddButton: boolean;
  @Input() displayCloseButton = true;

  /**
   * If you are going to use this component as part of a form, set it to true
   */
  @Input() isFormControl: boolean;

  /**
   * Values to display in the dropdown
   */
  @Input() set options(options: unknown[]) {
    this._options = options;
    this.filteredOptions = options;
    this.clearValue()
    //this.value = options && options.length>0 ? options[0] : undefined
  }

  /**
   * How to render a option
   */
  @Input() onRender: (option: unknown, container: ViewContainerRef, resolver: ComponentFactoryResolver) => {};

  /**
   * The key inside each option that will be displayed
   * @example
   * If this.options is an array of objects like so: { name: 'name', 'value': 'value'},
   * a selector could be 'name', and the value of that key will be displayed in the dropdown and input
   */
  @Input() selector: string; 

  value: unknown;
  onChange;
  onTouched;
  dropdownOpen: boolean;

  filteredOptions: unknown[];
  private _options: unknown[];

  constructor(private readonly changeDetection: ChangeDetectorRef, private resolver: ComponentFactoryResolver) {}

  toggleDropdown(option: boolean) {
    this.dropdownOpen = option;
  }

  displayValue(option): string {
    if(!option) {return undefined}
    const attributeName = option[this.selector];
    const selectors = this.selector.split('+');
    if(attributeName === undefined && selectors.length > 1) {
        let conformedName = '';
        selectors.forEach(attr => {
          if (option[attr] == undefined) return attributeName;
          conformedName += (option[attr] + ' ')
        })
        return conformedName.trim();
    }
    return attributeName;
  }

  onInputChange(event: HTMLInputElement) {
    this.clearValue();
    if (this.selector) {
      this.filteredOptions =
        this._options &&
        this._options.filter((option) => this.displayValue(option).toLowerCase().includes(event.value.toLowerCase()));
    } else {
      this.filteredOptions =
        this._options &&
        this._options.filter((option) => {
          const values = Object.getOwnPropertyNames(option)
            .map((attribute) => option[attribute])
            .filter((value) => typeof value === 'string');
          for (const value of values) {
            if (value.toLowerCase().includes(event.value.toLowerCase())) {
              return true;
            }
          }
          return false;
        });
    }

    this.changeDetection.detectChanges();
  }

  onOptionClicked(option: unknown) {
    this.value = option;
    this.optionClicked.emit(option);
    if (this.isFormControl) {
      this.onChange(option);
      this.onTouched(option);
    }
    this.changeDetection.detectChanges();
  }

  onAddNewClicked(event: MouseEvent) {
    this.addNewClicked.emit();
    event.preventDefault();
  }

  onRenderValue(option: unknown, container: ViewContainerRef) {
    if (this.onRender) {
      this.onRender(option, container, this.resolver);
      return ' ';
    } else {
      return option;
    }
  }

  clearValue() {
    if (this.containerSelected) {
      this.containerSelected.clear();
    }
    this.onOptionClicked(null);
  }

  writeValue(firstValue: unknown) {
    this.value = firstValue;
    this.changeDetection.detectChanges();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
