<div class="home-section">
    <div class="home-content pl-5">
        <div class="container-templates">
            <h1 style="padding:0px" translate>{{'home.templates'}}</h1>
            <div class="toolbar">
                <div class="dark-search-bar" style="width: 100%; display: flex; gap: 0.25rem; align-items: center;">
                    <label translate>{{'form.search'}}</label>
                    <input class="search-bar" (keyup)="templateFilter($event)" />   
                    
                    <a class="manage-template" [routerLink]="['./template-editor']">
                        <div class="image is-48x48">
                            <img src="assets/icons/create-project.svg"/>
                        </div>
                        <div translate>{{'home.manageTemplates'}}</div>
                    </a>
                </div>
                
            </div>
            <div class="templates">
                <a *ngFor="let projectStr of filteredProjectsAndDocs" class="template ml-1" (click)="showDocumentsForProject(projectStr.project)" >
                    <a class="image is-48x48 mr-1" (click)="openUserDocument(recent)">
                        <img src="assets/icons/document-file.svg"/>
                    </a>
                    <div>{{projectStr.project.projectName}}</div>
                </a>
            </div>
        </div>   
        <div class="recents">
            <h1 style="padding:0px" translate>{{'home.recentDocuments'}}</h1>
            <div class="toolbar">
                <div class="dark-search-bar" style="width: 72%; display: flex; gap: 0.25rem">
                    <label translate>{{'form.search'}}</label>
                    <input class="search-bar" (keyup)="recentFilter($event)"/>    
                </div>
            </div>

            <div class="box mr-5">
                <div *ngFor="let recent of filteredRecentDocs" class="document">
                        <a class="image is-24x24 mr-1" (click)="openUserDocument(recent)">
                            <img src="assets/icons/document-file.svg"/>
                        </a>
                        <p class="document-name" title="{{recent.userDocumentName}}" (click)="openUserDocument(recent)">{{recent.userDocumentName}}</p>
                    <p class="date" title="{{recent.updatedAt}}">{{recent.updatedAt}}</p>
                    <div class="is-flex is-justify-content-end" style="width: fit-content">
                        <a class="image is-24x24" (click)="openUserDocument(recent)">
                            <img src="assets/icons/edit-icon-fill.svg"/>
                        </a>
                        <a class="image is-24x24" (click)="downloadPdf(recent.userDocumentId)">
                            <img src="assets/icons/download-file.svg"/>
                        </a>
                        <a class="image is-24x24" (click)="showConfirmationDialogDeleteDocument(recent)">
                            <img src="assets/icons/trash-doc-icon.svg"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #openExistingProject class="modal is-clipped">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title" translate>{{'home.modals.selectDocumentType'}}</h1>
      <button class="delete" aria-label="close" (click)="hideDocumentsForProject()"></button>
    </header>
    <section class="modal-card-body" >
        <p class="mb-3" translate>{{'templates.area' | translate}} {{selectedProject.projectName}}</p>

      <!-- Content ... -->
        <form [formGroup]="selectDocumentForm">
            <app-autocomplete-input
                class="app__header-search"
                [options]="documentTypes"
                (optionClicked)="documentSelected($event)"
                [placeholder]="'home.searchPlaceholder'"
                [notFoundPlaceholder]="'form.noElementsInSearch'"
                selector="documentName"
                formControlName="document"
                [isFormControl]="true"
            ></app-autocomplete-input>
            <p class="help" translate>{{'home.modals.selectDocumentTypeTip'}}</p>

            <br>
            <button 
                [disabled]="selectDocumentForm && selectDocumentForm.invalid" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                (click)="startDocument()"
                translate
            >{{'form.continue'}}</button>
        </form>
        
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</div>


<div #openDeleteDocument class="modal is-clipped">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title" translate>{{'home.modals.removeDocument'}}</h1>
      <button class="delete" aria-label="close" (click)="hideConfirmationDialogDeleteDocument()"></button>
    </header>
    <section class="modal-card-body" >
        <p class="mb-3" translate>{{'templates.document' | translate}}: {{selectedDocument.userDocumentName}}</p>

      <!-- Content ... -->
        
    </section>
    <footer class="modal-card-foot is-fex is-justify-content-space-between">
        <button class="button is-danger" (click)="deleteUserDocument()" translate>{{'form.delete'}}</button>
        <button class="button is-info" (click)="hideConfirmationDialogDeleteDocument()" translate>{{'form.cancel'}}</button>
    </footer>
  </div>
</div>