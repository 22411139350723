<div class="manage-project-section">
    <h1 translate>{{'manageTemplates.title'}}</h1>
    <div class="mt-1 columns">
        <div class="elements column is-3">
            <div [ngClass]="{'selected-element': activeTab == 'projects'}" (click)="setActiveTab('projects')">
                <div class="icon-text-group m-2">
                    <div class="icon-text">
                        <div class="image is-36x36 mr-3">
                            <img src="assets/icons/project-icon.svg"/>
                        </div>
                        <div translate>{{'templates.area'}}</div>
                    </div>
                    <div class="image is-36x36 mr-3">
                        <img src="assets/icons/arrow_right.svg"/>
                    </div>
                     <div class="is-primary item-placeholder">{{selectedProject && selectedProject.projectName}}</div>
                </div>
            </div>
            <div [ngClass]="{'selected-element': activeTab == 'documents'}" (click)="!!selectedProject && setActiveTab('documents')">
                <div class="icon-text-group m-2">
                    <div class="icon-text">
                        <div class="image is-36x36 mr-3">
                            <img src="assets/icons/document-file.svg"/>
                        </div>
                        <div translate>{{'templates.document'}}</div>
                    </div>
                    <div class="image is-36x36 mr-3">
                        <img src="assets/icons/arrow_right.svg"/>
                    </div>
                     <div class="is-primary item-placeholder">{{selectedDocument && selectedDocument.documentName}}</div>
                </div>
            </div>
            <div [ngClass]="{'selected-element': activeTab == 'sections'}" (click)="!!selectedDocument && setActiveTab('sections')">
                <div class="icon-text-group m-2">
                    <div class="icon-text">
                        <div class="image is-36x36 mr-3">
                            <img src="assets/icons/section-icon.svg"/>
                        </div>
                        <div translate>{{'templates.section'}}</div>
                    </div>
                    <div class="image is-36x36 mr-3">
                        <img src="assets/icons/arrow_right.svg"/>
                    </div>
                     <div class="is-primary item-placeholder">{{selectedSection && selectedSection.sectionName}}</div>
                </div>
            </div>
            <div [ngClass]="{'selected-element': activeTab == 'options'}" (click)="!!selectedSection && setActiveTab('options')">
                <div class="icon-text-group m-2">
                    <div class="icon-text">
                        <div class="image is-36x36 mr-3">
                            <img src="assets/icons/option-icon.svg"/>
                        </div>
                        <div translate>{{'templates.option'}}</div>
                    </div>
                    <div class="image is-36x36 mr-3">
                        <img src="assets/icons/arrow_right.svg"/>
                    </div>
                     <div class="is-primary item-placeholder">{{selectedOption && selectedOption.optionName}}</div>
                </div>
            </div>
        </div>
        <div class="contents column">
            <project-tree [document]="selectedDocument" [tree]="tree"></project-tree>
            <h3 class="modal-card-title mb-4" >{{'manageTemplates.manage' | translate}} {{getSelectedItemName().selectedItem | translate}}</h3> 
            <div class="box ">
                <div class="columns">
                    <div class="column is-3 actions">
                        <div class="action">
                            <div class="ml-2 button add-button is-primary is-rounded" 
                                (click)="showNewElementModal()"
                            >+</div>
                            <div><p >{{'manageTemplates.add' | translate}} {{getSelectedItemName().selectedItem  | translate}}</p></div>
                        </div>
        
                        <div class="action">
                            <div class="ml-2 button add-button is-info is-rounded" 
                            (click)="showEditElementModal()">
                                <span class="icon image is-36x36">
                                    <img src="assets/icons/edit-icon.svg"/>
                                </span>
                            </div>
                            <div><p >{{'manageTemplates.edit' | translate}} {{getSelectedItemName().selectedItem | translate}}</p></div>
                        </div>
                        <div class="action">
                            <div class="ml-2 button add-button is-danger is-rounded" 
                            (click)="showDeleteElementModal()">
                                <span class="icon image is-36x36">
                                    <img src="assets/icons/trash-can.svg"/>
                                </span>
                            </div>
                            <div><p>{{'manageTemplates.delete'| translate}} {{getSelectedItemName().selectedItem | translate}}</p></div>
        
                        </div>
                        
                    </div>
        
                    <div class="column project-selector"  >
                        <p >{{'manageTemplates.select' | translate}} {{getSelectedItemName().selectedItem | translate}} </p>
                        <app-autocomplete-input
                            class="dark-search-bar"
                            [options]="getChoices()"
                            (optionClicked)="choiceSelected($event)"
                            [placeholder]="'form.noElementsInSearch'"
                            [notFoundPlaceholder]="'No se ha encontrado elementos'"
                            [selector]="getChoiceSelector()"
                            [isFormControl]="false"
                        ></app-autocomplete-input>
                    </div>
                </div>   
            </div>

            <div class="box"  *ngIf="selectedOption">
                <tiptap-editor
                    [initialContent]="selectedOption.optionTemplate"
                    [isSaving]="isSaving"
                    (saveContent)="saveOptionTemplate($event)"
                ></tiptap-editor>
            </div>
        </div>
    </div>
</div>

<div #newElementModal class="modal is-clipped">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title">{{'manageTemplates.add' | translate}} {{getSelectedItemName().selectedItem | translate}}</h1>
      <button class="delete" aria-label="close" (click)="hideNewElementModal()"></button>
    </header>
    <section class="modal-card-body" >
      <!-- Content ... -->
        <form form [formGroup]="formCreateResource" (submit)="addNewOfChoice()">
            <div class="field">
                <label class="label" translate>{{'manageTemplates.name'}}</label>
                <div class="control">
                    <input formControlName="name" class="input" type="text" placeholder="{{'manageTemplates.name' | translate}}">
                </div>
            </div>
            <div *ngIf="activeTab == 'sections'" class="field" >
                <div class="control">
                    <label class="label" translate>{{'templates.sectionOrder'}}</label>
                    <input formControlName="order" class="input" type="text" placeholder="1.2.3" >
                </div>
            </div>
            <button 
                [disabled]="formCreateResource && formCreateResource.invalid" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                translate
            >{{'form.continue'}}</button>
        </form> 
        
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</div>

<div #deleteElementModal class="modal is-clipped">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title" translate>
            {{'form.delete'}}  <span> {{getSelectedItemName().selectedItem | translate}}</span>
      </h1>
      <button class="delete" aria-label="close" (click)="hideDeleteElementModal()"></button>
    </header>
    <section class="modal-card-body" >
      <!-- Content ... -->
        <form form [formGroup]="formDeleteResource" (submit)="deleteResourceOfChoice()">
            <div class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <app-autocomplete-input
                        class="dark-search-bar"
                        [options]="getChoices()"
                        [placeholder]="'form.search'"
                        [notFoundPlaceholder]="'form.noElementsInSearch'"
                        [selector]="getChoiceSelector()"
                        [isFormControl]="true"
                        formControlName="resource"
                    ></app-autocomplete-input>
                </div>
            </div>
            <button 
                [disabled]="formDeleteResource && formDeleteResource.invalid" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                translate
            >{{'form.delete'}}</button>
        </form>
        
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</div>

<div #editElementModal class="modal is-clipped">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title" translate>
            {{'manageTemplates.edit'}} <span > {{getSelectedItemName().selectedItem | translate}}</span>
      </h1>
      <button class="delete" aria-label="close" (click)="hideEditElementModal()"></button>
    </header>
    <section class="modal-card-body" >
      <!-- Content ... -->
        <form form [formGroup]="formEditResource" (submit)="editResourceOfChoice()">
            <div class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <app-autocomplete-input
                        class="dark-search-bar"
                        [options]="getChoices()"
                        [placeholder]="'form.search'"
                        [notFoundPlaceholder]="'form.noElementsInSearch'"
                        [selector]="getChoiceSelector()"
                        [isFormControl]="true"
                        formControlName="resource"
                    ></app-autocomplete-input>
                </div>
            </div>
            <div *ngIf="activeTab !== 'sections'" class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <input class="input"  type="text"  formControlName="name"
                            [value]="formEditResource.controls.resource?.value !== null ? formEditResource.controls.resource?.value[getChoiceSelector()] && formEditResource.controls.resource?.value[getChoiceSelector()]: ''"
                            [attr.disabled]="formEditResource.controls.resource?.invalid ? '' : null" >
                </div>
            </div> 
            <div *ngIf="activeTab == 'sections'" class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <input class="input"  type="text" formControlName="name"
                            [value]="formEditResource.controls.resource?.value !== null ? formEditResource.controls.resource?.value.sectionName : ''"
                            [attr.disabled]="formEditResource.controls.resource?.invalid ? '' : null" >
                </div>
            </div> 
            <div *ngIf="activeTab == 'sections'" class="field">                
                <div class="control">
                    <label class="label" translate>{{'templates.sectionOrder'}}</label>
                    <input class="input"  type="text" formControlName="order" 
                            [value]="formEditResource.controls.resource?.value !== null ? formEditResource.controls.resource?.value.sectionOrder: ''"
                            [attr.disabled]="formEditResource.controls.resource?.invalid ? '' : null">
                </div>
            </div>
            <button 
                [disabled]="formEditResource && formEditResource.invalid && !formEditResource.controls.order?.dirty && !formEditResource.controls.order?.touched" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                translate
            >{{'form.continue'}}</button>
        </form>
        
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</div>