import { Component, OnInit } from "@angular/core";
import { PaymentInterval, Plan, Subscription, SubscriptionStatus } from "@services/subscription/subscription.model";
import { SubscriptionService } from "@services/subscription/subscription.service";

@Component({
    selector: 'plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss']
  })
  export class PlansComponent implements OnInit {
    myUserId: string;
    plans: Array<Plan> = [];
    subscriptions: Array<Subscription> = [];
    intervals: Array<PaymentInterval>;
    selectedInterval: PaymentInterval;
    selectedPlans: Array<Plan> = [];

    constructor(public subscriptionService: SubscriptionService) {}

    ngOnInit(): void {
      let currentUser = localStorage.getItem('currentUser')
      if(currentUser) {
          this.myUserId = JSON.parse(currentUser).userId;
      }

        this.subscriptionService.getPlans().subscribe(res => {
            this.plans = res;
            this.intervals = [...new Set(res.map(p => p.interval))];
            this.selectedInterval = this.intervals[0];
            this.selectedPlans = this.plans.filter(p => p.interval === this.selectedInterval);
        })
        this.subscriptionService.querySubscriptions().subscribe(subscriptions => {
          this.subscriptions = subscriptions;
        })
    }

    setSelectedInterval(interval: PaymentInterval) {
      this.selectedInterval = interval;
      this.selectedPlans = this.plans.filter(p => p.interval === this.selectedInterval);
    }

    subscribeToPlan(plan: Plan) {
      this.subscriptionService.subscribe(plan.id)
        .subscribe((paymentUrl) =>{
          console.log(paymentUrl)
          window.location.href = paymentUrl;
        })
    }

}