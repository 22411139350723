import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public bigScreen = true;
  constructor() { }

  ngOnInit(): void {
    this.bigScreen = window.innerWidth >= 1150;
    window.onresize = () => this.bigScreen = window.innerWidth >= 1150;
  }

}
