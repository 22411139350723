import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from '../directives/directives.module';

import { AutocompleteInputComponent } from './autocomplete-input.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [AutocompleteInputComponent],
  imports: [CommonModule, DirectivesModule, FontAwesomeModule, TranslateModule],
  exports: [AutocompleteInputComponent],
})
export class AutocompleteInputModule {}
