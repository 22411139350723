import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import './menu.style.scss';

@Component({
    selector: 'tiptap-menu-editor',
    template: `
            <div class="tiptap-editor__header">
                <ng-container *ngFor="let item of items"> 
                    <div *ngIf="item.type === 'divider'"><div class="divider"> </div></div>
                    <div *ngIf="item.type !== 'divider'">
                        <tiptap-menu-item-editor [item]="item"></tiptap-menu-item-editor>
                    </div>
                </ng-container>
                <modal 
                  [showModal]="isModalForPlaceholderOpen" 
                  [title]="'editor.addplaceholderTitle' | translate"
                  (modalClosed)="closeModalForPlaceholder()">
                  <form form 
                    [formGroup]="formAddPlaceholder" 
                    (submit)="addPlaceholder()">

                    <div class="field">
                      <label class="label" translate>{{'editor.nameOfPlaceholder'}}</label>
                      <div class="control">
                          <input formControlName="title" class="input" type="text" placeholder="{{'editor.nameOfPlaceholder' | translate}}">
                      </div>
                    </div>

                    <div class="field">
                      <label class="label" translate>{{'editor.descriptionOfPlaceholder'}}</label>
                      <div class="control">
                          <input formControlName="placeholder" class="input" type="text" placeholder="{{'editor.descriptionOfPlaceholder' | translate}}">
                      </div>
                    </div>

                    <div>
                      <button
                      [disabled]="formAddPlaceholder && formAddPlaceholder.invalid" 
                      class="button is-primary is-rounded is-pulled-right" 
                      type="submit"
                      >{{'form.create' | translate}}</button>
                    </div>

                  </form>
                </modal>
            </div>`,
    styleUrls:['./menu.style.scss']
  })
  export class MenuComponent implements OnInit, OnChanges {
    @Input() public editor: any;
    @Input() public hasPlaceholderButton: boolean = false;
    @Input() public isEditingTable: boolean = false;
    public isModalForPlaceholderOpen: boolean = false;
    formAddPlaceholder: FormGroup;

    ngOnInit(): void {
      this.formAddPlaceholder = new FormGroup({
        placeholder: new FormControl('', Validators.required),
        title: new FormControl('', Validators.required),
      });
      if(this.hasPlaceholderButton) {
        this.items.push({
          icon: 'survey-line',
          title: 'addPlaceholder',
          action: () => {
            // ask for options data
            this.isModalForPlaceholderOpen = true;
          },
        })
      }
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (!changes.isEditingTable || changes.isEditingTable.currentValue == changes.isEditingTable.previousValue) {
        return;
      }
      if (changes.isEditingTable.currentValue) {
        this.showTableOptions();
      } else {
        this.removeTableOptions();
      }
    }

    showTableOptions() {
      this.items = this.items.concat(this.tableOptions);
    }

    removeTableOptions() {
      this.items = this.items.filter(opt => !this.tableOptions.includes(opt));
    }

    closeModalForPlaceholder() {
      this.isModalForPlaceholderOpen = false;
      this.formAddPlaceholder.reset();
    }

    addPlaceholder() {
      let option = {
        id: new Date().getTime(), 
        placeholder: this.formAddPlaceholder.value.placeholder, 
        title: this.formAddPlaceholder.value.title
      }
      this.editor.chain().focus().setPlaceholder(option).run()
      this.closeModalForPlaceholder();
    }

    items = [
      {
        icon: 'bold',
        title: 'Bold',
        action: () => this.editor.chain().focus().toggleBold().run(),
        isActive: () => this.editor.isActive('bold'),
      },
      {
        icon: 'italic',
        title: 'Italic',
        action: () => this.editor.chain().focus().toggleItalic().run(),
        isActive: () => this.editor.isActive('italic'),
      },
      {
        icon: 'strikethrough',
        title: 'Strike',
        action: () => this.editor.chain().focus().toggleStrike().run(),
        isActive: () => this.editor.isActive('strike'),
      },
      {
        icon: 'code-view',
        title: 'Code',
        action: () => this.editor.chain().focus().toggleCode().run(),
        isActive: () => this.editor.isActive('code'),
      },
      {
        icon: 'code-box-line',
        title: 'Code Block',
        action: () => this.editor.chain().focus().toggleCodeBlock().run(),
        isActive: () => this.editor.isActive('codeBlock'),
      },
      {
        icon: 'mark-pen-line',
        title: 'Highlight',
        action: () => this.editor.chain().focus().toggleHighlight().run(),
        isActive: () => this.editor.isActive('highlight'),
      },
      {
        type: 'divider',
      },
      {
        icon: 'h-1',
        title: 'Heading 1',
        action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
        isActive: () => this.editor.isActive('heading', { level: 1 }),
      },
      {
        icon: 'h-2',
        title: 'Heading 2',
        action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
        isActive: () => this.editor.isActive('heading', { level: 2 }),
      },
      {
        icon: 'list-unordered',
        title: 'Bullet List',
        action: () => this.editor.chain().focus().toggleBulletList().run(),
        isActive: () => this.editor.isActive('bulletList'),
      },
      {
        icon: 'list-ordered',
        title: 'Ordered List',
        action: () => this.editor.chain().focus().toggleOrderedList().run(),
        isActive: () => this.editor.isActive('orderedList'),
      },
      {
        type: 'divider',
      },
      {
        icon: 'separator',
        title: 'Horizontal Rule',
        action: () => this.editor.chain().focus().setHorizontalRule().run(),
      },
      {
        type: 'divider',
      },
      {
        icon: 'grid-line',
        title: 'Add table',
        action: () => this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
      },
      {
        type: 'divider',
      },
      {
        icon: 'text-wrap',
        title: 'Hard Break',
        action: () => this.editor.chain().focus().setHardBreak().run(),
      },
      {
        icon: 'format-clear',
        title: 'Clear Format',
        action: () => this.editor.chain().focus().clearNodes().unsetAllMarks()
          .run(),
      },
      {
        type: 'divider',
      },
    ]
    
    tableOptions = [
      {
        icon: 'insert-row-top',
        title: 'Insert Row Before',
        action: () => this.editor.chain().focus().addRowBefore().run(),
      },
      {
        icon: 'insert-row-bottom',
        title: 'Insert Row After',
        action: () => this.editor.chain().focus().addRowAfter().run(),
      },
      {
        icon: 'delete-row',
        title: 'Delete Row',
        action: () => this.editor.chain().focus().deleteRow().run(),
      },
      {
        type: 'divider',
      },
      {
        icon: 'insert-column-left',
        title: 'Insert Column Left',
        action: () => this.editor.chain().focus().addColumnBefore().run(),
      },
      {
        icon: 'insert-column-right',
        title: 'Insert Column Right',
        action: () => this.editor.chain().focus().addColumnAfter().run(),
      },
      {
        icon: 'delete-column',
        title: 'Delete Column',
        action: () => this.editor.chain().focus().deleteColumn().run(),
      },

      {
        type: 'divider',
      },
      {
        icon: 'split-cells-horizontal',
        title: 'Split Cell',
        action: () => this.editor.chain().focus().splitCell().run(),
      },
      {
        icon: 'split-cells-horizontal',
        title: 'Merge Cell',
        action: () => this.editor.chain().focus().mergeCells().run(),
      },
      {
        icon: 'layout-column-fill',
        title: 'Toggle Header Column',
        action: () => this.editor.chain().focus().toggleHeaderColumn().run(),
      },
      {
        icon: 'layout-row-fill',
        title: 'Toggle Header Row',
        action: () => this.editor.chain().focus().toggleHeaderRow().run(),
      },
      {
        icon: 'archive-drawer-line',
        title: 'Toggle Header Cell',
        action: () => this.editor.chain().focus().toggleHeaderCell().run(),
      },
      {
        type: 'divider',
      },
      {
        icon: 'delete-bin-4-line',
        title: 'Delete Table',
        action: () => this.editor.chain().focus().deleteTable().run(),
      },
      {
        type: 'divider',
      },
    ]
  }