<div class="layout">
    <div class="left-white">
        <h1 class="thin-title" translate>{{'recoverPassword.title'}}</h1>    
        
        <div class='content'>
            <p class="thin-subtitle" style="text-align:'center'" translate>{{'recoverPassword.subtitle'}}</p>
            <form [formGroup]="form" (ngSubmit)="forgotPassword()">
                <div class="field">
                    <div class="control">
                    <input class="input" type="email" placeholder="{{'form.email' |translate}}" formControlName="email" autofocus="">
                    </div>
                </div>
                <div *ngIf="form.controls.email?.invalid && (form.controls.email?.dirty || form.controls.email?.touched)" class="message is-danger">
                    <div class="message-body" translate><strong>{{'form.error' | translate}}:</strong> {{'form.wrongEmail'}}</div>
                </div>
                <button class="button mt-3 is-block is-success is-fullwidth"
                    type="submit"
                    [disabled]="form.invalid"
                    translate
                    >
                    {{'recoverPassword.recover'}}
                </button>
            </form>
        </div>
        

    </div>

    <div class="right-color gradient-background">
        <h1 class="thin-title white-color" translate>{{'login.logIn'}}</h1>
        <h2 class="thin-subtitle white-color" translate>{{'login.loginSubtitle'}}</h2>
        <div style="width: '60%'; text-align: 'center'; margin-top: 1rem;">
            <a class="alternative-button" href="/login" translate>{{'login.logIn'}}</a>
        </div>

        <div class='diagonal-div'></div>
    </div>
</div>