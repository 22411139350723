<nav class="navbar header" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item logo" href="https://docuemntally.fundamentally.eu">
      <img src="/assets/logo/MTA_logo.png"><p class = "brand-name"></p>
    </a>

    <a (click)="toggleNavbar()" role="button" #navBurger class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="nav-menu">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="nav-menu" class="navbar-menu" #navMenu>
    <!-- navbar-start, navbar-end... -->
    <div class="navbar-end ">

      <!-- navbar items -->
      <a class="navbar-item" [routerLink]="['/']">
        <div class="icon-text">
            <div class="icon">
                <img style="fill: black"src="assets/icons/home-24px.svg"/>
            </div>
            <div translate>{{'navigation.home'}}</div>
        </div>
      </a>

      <a class="navbar-item" [routerLink]="['/settings']">
          <div class="icon-text">
              <div class="icon">
                <img style="fill: black"src="assets/icons/settings.svg"/>
              </div>
              <div translate>{{'navigation.settings'}}</div>
          </div>
      </a>

      <a class="navbar-item" (click)="logout()" >
        <div class="icon-text">
          <div class="icon">
            <img style="fill: black"src="assets/icons/sign-out.svg"/>
          </div>
          <div translate>{{'navigation.signOut'}}</div>
        </div>
      </a>

      <a class="navbar-item" >
        <div class="icon-text">
          <div class="icon" (click)="setShowLanguages()">
            <img style="fill: black"src="assets/icons/lang-selector.png"/>
          </div>
          <div *ngIf="showLanguages" class="languages">
            <div class="language" (click)="selectLanguage('es')"><p>Español</p></div>
            <div class="language" (click)="selectLanguage('en')"><p>English</p></div>
            <div class="language" (click)="selectLanguage('nl')"><p>Dutch</p></div>
          </div>
        </div>
      </a>
      
      <div class="navbar-item" >
        <div class="columns is-vcentered" style="width: fit-content">
          <div class="column">
            <p style="min-width: 150px; text-align: right;" >{{username}}</p>
          </div>

          <div class="column">
            <figure class="image is-48x48">
              <ngx-avatar name="{{username}}" size="48"></ngx-avatar>
            </figure>
          </div>
        </div>
      </div>

    </div>
    
  </div>
</nav>