import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TemplatesFacade } from '@services/templates/templates.facade';
import { Document, Project, ProjectDocuments, UserDocument, UserDocumentData } from '@services/templates/templates.model';
import { UserFacade } from '@services/user/user.facade';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('openExistingProject') modal: ElementRef;
  @ViewChild('openDeleteDocument') openDeleteDocumentModal: ElementRef;
  selectedDocument: UserDocument = {} as UserDocument;

  selectDocumentForm: FormGroup;
  selectedProject: Project = {} as Project;
  projectsAndDocs: Array<ProjectDocuments> = [];
  filteredProjectsAndDocs: Array<ProjectDocuments> = [];
  recentDocs: Array<UserDocument> = [];
  filteredRecentDocs: Array<UserDocument>;

  documentTypes: Array<Document> = [];
  userId: string;

  constructor(private templateFacade: TemplatesFacade, 
    private readonly userFacade: UserFacade, 
    private readonly router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.createForm();
    this.templateFacade.projectsAnDocuments();
     this.templateFacade.getProjectsAnDocuments.subscribe( 
       (projectsAndDocs) => {
         this.projectsAndDocs = projectsAndDocs;
         this.filteredProjectsAndDocs = projectsAndDocs;
         if(projectsAndDocs && projectsAndDocs.length > 0){
            this.selectedProject = projectsAndDocs[0].project
         }
         
       }
     );

     this.templateFacade.recentDocuments.subscribe(
       recents => {
        this.filteredRecentDocs = recents;
        this.recentDocs = recents;
     });

     this.userFacade.currentUser.subscribe((user) => {
      if (user && this.userId !== user.userId) {
        this.userId = user.userId;
        this.templateFacade.loadRecentDocuments(this.userId);
      }
    })
  }

  templateFilter(event) {
    let filter = event.target.value;
    if(filter ) {
      this.filteredProjectsAndDocs = this.projectsAndDocs.filter(rd => rd.project.projectName.toLowerCase().includes(filter.toLowerCase()));
    } else {
      this.filteredProjectsAndDocs = this.projectsAndDocs;
    }
  }

  recentFilter(event) {
    let filter = event.target.value;
    if(filter ) {
      this.filteredRecentDocs = this.recentDocs.filter(rd => rd.userDocumentName.toLowerCase().includes(filter.toLowerCase()));
    } else {
      this.filteredRecentDocs = this.recentDocs;
    }
  }

  getProjects(): Array<Project> {
    return this.projectsAndDocs.map(pr => pr.project)
  }

  showDocumentsForProject(project:Project) {
    this.selectedProject = project;
    let selected = this.projectsAndDocs.find(e => e.project.projectId === project.projectId);
    this.documentTypes = selected && selected.documents;
    this.modal.nativeElement.classList.add('is-active');
  }

  hideDocumentsForProject() {
    this.modal.nativeElement.classList.remove('is-active');
  }

  showConfirmationDialogDeleteDocument(document) {
    this.selectedDocument = document;
    this.openDeleteDocumentModal.nativeElement.classList.add('is-active');
  }

  hideConfirmationDialogDeleteDocument() {
    this.selectedDocument = null;
    this.openDeleteDocumentModal.nativeElement.classList.remove('is-active');
  }

  createForm() {
    this.selectDocumentForm = new FormGroup({
      document: new FormControl(this.documentTypes, [Validators.required, Validators.nullValidator]),
    });
  }

  documentSelected(document: Document) {
    if(document) {

    }
  }

  startDocument() {
    let documentId = this.selectDocumentForm.value.document.documentId;
    console.log("Starting document ...", `/edit-document/${this.selectedProject.projectId}/${documentId}`)
    let userDocumentReq = {
      documentId: documentId,
      userId: this.userId,
      projectId:  this.selectedProject.projectId,
      userDocumentName: 'Nuevo Proyecto', 
      userDocumentData: JSON.stringify({sections:[]} as UserDocumentData)
    } as UserDocument;
    this.templateFacade.createUserDocument(this.userId, userDocumentReq);
    this.templateFacade.userDocument.subscribe(
      userDocument => {
        if(!userDocument){return;}
        console.log("create User Document", userDocument)
        this.router.navigate([`./edit-document/${this.selectedProject.projectId}/${this.userId}/${userDocument.userDocumentId}`]);
        this.hideDocumentsForProject();
      },
      error => console.log("Error")
    );
  
  }

  openUserDocument(userDocument: UserDocument) {
    if(userDocument){
      this.router.navigate([`./edit-document/${userDocument.projectId}/${this.userId}/${userDocument.userDocumentId}`]);
    }
  }

  deleteUserDocument() {
    this.templateFacade.deleteUserDocument(this.userId, this.selectedDocument.userDocumentId);
    this.hideConfirmationDialogDeleteDocument();
  }

  downloadPdf(userDocumentId) {
    this.spinner.show();
    this.templateFacade.generatePDFDocument(this.userId, userDocumentId).subscribe((data) =>{
      this.spinner.hide();
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL, '_blank');
    },
    () => {
      this.spinner.hide();
      alert("Error generando documento.");

    })
  }

}
