<div #documentTree class="document-tree" style="zoom: 75%;">
    <h1 *ngIf="document" class="document element">{{document.documentName}} </h1>
    <ol *ngIf="document" class="sections">
        <li *ngFor="let section of tree">
            <h2 
                *ngIf="section"
                class="element section" 
                (click)="clickItem(''+section.sectionId)"
                [ngClass]="{'active' : clickedItem == section.sectionId}">
                {{section.sectionOrder}} {{section.sectionName}}</h2>
            <ol *ngIf="section && section.options" class="options">
                <li *ngFor="let option of section.options">
                    <h4 
                        class="element option" 
                        (click)="clickItem(section.sectionId + '-' + option.optionId)"
                        [ngClass]="{'active' : clickedItem == section.sectionId + '-' + option.optionId}">
                        {{option.optionName}}</h4>
                </li>
            </ol>
        </li>
    </ol>    
</div>
<button *ngIf="document" (click)="zoomIn()">+</button>
<button *ngIf="document"  (click)="zoomOut()">-</button>