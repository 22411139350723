<section class="autocomplete-input__input" appOutsideClick (outsideClick)="toggleDropdown(false)">
  <template viewContainer #containerSelected="viewContainer"></template>
  <input
    #inputElement
    class="autocomplete-input__input-element"
    [value]=" displayValue(value)? displayValue(value)
              : placeholder | translate
            "
    [placeholder]="placeholder | translate "
    (focusin)="toggleDropdown(true)"
    (focusout)="toggleDropdown(false)"
    (keyup)="onInputChange(inputElement)"
  />
  <em class="far fa-times-circle clear-button" *ngIf="value && displayCloseButton" (click)="clearValue()"></em>
  <em
    class="fas"
    [ngClass]="{'fa-chevron-up': dropdownOpen, 'fa-chevron-down': !dropdownOpen}"
    (click)="toggleDropdown(!dropdownOpen)"
  ></em>
</section>

<section
  [ngClass]="{
    'autocomplete-input__dropdown--open': dropdownOpen,
    'autocomplete-input__dropdown': !dropdownOpen
    }"
>
  <button
    *ngFor="let option of filteredOptions; let i = index"
    [ngClass]="{ 'autocomplete-input__dropdown-element--active': option !== undefined && option === value && displayValue(value) }"
    class="autocomplete-input__dropdown-element"
    (mousedown)="onOptionClicked(option)"
  >
    <em [className]="option?.icon"></em>
    <template viewContainer #cnt="viewContainer"></template>
    {{  option !== undefined && selector ? displayValue(option) : placeholder | translate }}
  </button>
  <p class="autocomplete-input__dropdown-element" *ngIf="notFoundPlaceholder && !filteredOptions?.length">
    {{notFoundPlaceholder | translate}}
  </p>
  <button
    *ngIf="displayAddButton"
    (mousedown)="onAddNewClicked($event)"
    class="autocomplete-input__dropdown-element autocomplete-input__dropdown-element--add"
  >
    <em class="fas fa-plus"></em> Añadir
  </button>
</section>
