<div class="columns">
    <div *ngIf="subscriptions && subscriptions.length > 0 " class="profileForm box" style="width: 100%;">
        <div class="subscriptions">
            <div *ngFor="let subscription of subscriptions" class="columns is-justify-content-space-between">
        
                <div class="p-3 summary">
                    <label class="label" translate>{{'subscription-management.summary'}}</label>
                    <h3>{{'subscription-management.plan' | translate}}: {{subscription.plan.name}} <span>{{'subscription-management.status' | translate}} {{subscription.status}}</span></h3>
                    <p>{{'subscription-management.price' | translate}}: {{subscription.plan.price}} €</p>
                    <p>{{'subscription-management.paymentInterval' | translate}}: {{subscription.plan.interval}}</p>
                    <p>{{'subscription-management.membershipQuota' | translate}}: {{subscribersCount(subscription.id)}} / {{subscription.plan.numberSubscribers}}</p>
                </div>
        
                <div class="p-3 subscribers">
                    <label class="label" translate>{{'subscription-management.members'}}</label>
                    <ol>
                        <li *ngFor="let subscriber of getSubscribersInFirstSubscription()" 
                            class="is-flex is-align-items-center is-flex-wrap-wrap mb-3" >
                            <label>{{subscriber.memberId}}</label>
                            <button *ngIf="!subscriber.isActive" class="button is-block is-normal is-link" (click)="setMembershipMembership(subscriber.memberId, !subscriber.isActive)" >Activate</button>
                            <button *ngIf="subscriber.isActive" class="button is-block is-normal is-warning" (click)="setMembershipMembership(subscriber.memberId, !subscriber.isActive)" >Deactivate</button>
                        </li>
                    </ol>
                </div>
        
                <div class="p-3 actions">
                    <div class="field">
                        <label class="label" translate>{{'subscription-management.addMember'}}</label>
                        <div class="mt-3 is-flex is-align-items-center">
                            <label>CODE:</label>
                            <input  [(ngModel)]="subscriberId" class="input" type="text"  value=""/>
                            <button class="button is-block is-normal is-primary" (click)="addSubscriberToSubscription()">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <p class="help is-danger">{{subscriptionErrors}}</p>
        </div>
    </div>
</div>

