import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TemplatesFacade } from '@services/templates/templates.facade';
import { Project, ProjectDocuments, Document, Section, Option, SectionTree } from '@services/templates/templates.model';

@Component({
  selector: 'app-manage-projects',
  templateUrl: './manage-projects.component.html',
  styleUrls: ['./manage-projects.component.scss']
})
export class ManageProjectsComponent implements OnInit {
  @ViewChild('newElementModal') modal: ElementRef;
  @ViewChild('deleteElementModal') deleteModal: ElementRef;
  @ViewChild('editElementModal') editModal: ElementRef;
  formCreateResource: FormGroup;
  formDeleteResource: FormGroup;
  formEditResource: FormGroup;

  selectedProject: Project;
  selectedDocument: Document;
  selectedSection: Section;
  selectedOption: Option;

  activeTab = 'projects';
  isSaving = false;

  projectsAndDocs: Array<ProjectDocuments> = [];
  sections: Array<Section> = [];
  options: Array<Option> = [];

  tree: Array<SectionTree> = [];

  constructor(
    private templateFacade: TemplatesFacade, 
    private readonly changeDetection: ChangeDetectorRef,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.createForm();
    this.templateFacade.projectsAnDocuments();

    this.templateFacade.getProjectsAnDocuments.subscribe( 
      (projectsAndDocs) => {
        this.projectsAndDocs = projectsAndDocs;
      }
    );
    this.templateFacade.treeDocument.subscribe(sectionsTree => {
      this.tree = sectionsTree;
      
    });
    this.templateFacade.sectionsInDocument.subscribe(sections =>  this.sections = sections && [...sections].sort((a,b)=> comparatorByField(a, b, 'sectionId')))
    this.templateFacade.optionsInSection.subscribe(options => {
      this.options = options;  
      if(options){
        this.options = [...this.options].sort((a,b)=> comparatorByField(a, b, 'optionId'));
        if(this.selectedOption){
          this.selectedOption = this.options.find(o=>o.optionId === this.selectedOption.optionId);
          this.options = this.move([...this.options], this.options.indexOf(this.selectedOption), 0);
        }
      }
      // Allow animation to run for 1,5 secs so we have UI feedback for the saving
      setTimeout(() => this.isSaving = false , 1500);
    })
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
    this.changeDetection.detectChanges();
  }

  getSelectedItemName() {
    switch(this.activeTab) {
      case 'projects':
        return {selectedItem: 'templates.area'};
      case 'documents':
        return {selectedItem: 'templates.document'};
      case 'sections': 
        return {selectedItem: 'templates.section'}
      case 'options': 
        return {selectedItem: 'templates.option'}
      default:
        return {selectedItem: ''};
    }           
  }

  async getSelectedItemNames(tagToTranslate) {
    switch(this.activeTab) {
      case 'projects':
        return await this.translate.get('templates.area').toPromise().then(data => {
          return this.translate.get(tagToTranslate, {selectedItem: data}).toPromise<string>().then( res =>{ return res})
        })
      case 'documents':
        return await this.translate.get('templates.area').toPromise().then(data => {
          return this.translate.get(tagToTranslate, {selectedItem: data}).toPromise<string>().then( res =>{ return res})
        })
      case 'sections': 
        return await this.translate.get('templates.area').toPromise().then(data => {
          return this.translate.get(tagToTranslate, {selectedItem: data}).toPromise<string>().then( res =>{ return res})
        })
      case 'options': 
        return await this.translate.get('templates.area').toPromise().then(data => {
          return this.translate.get(tagToTranslate, {selectedItem: data}).toPromise().then( res =>{ return res})
        })
      default:
        return''
    }           
  }
  getChoices() {
    switch(this.activeTab) {
      case 'projects':
        return this.getProjects();
      case 'documents':
        return this.getDocuments();
      case 'sections': 
        return this.sections? this.sections : []
      case 'options': 
        return this.options? this.options : []
      default:
        return [];
    }
  }

  getChoiceSelector() {
    switch(this.activeTab) {
      case 'projects':
        return 'projectName';
      case 'documents':
        return 'documentName';
      case 'sections':
        return 'sectionOrder+sectionName';
      case 'options':
        return 'optionName';
      default:
        return 'name';
    }
  }

  choiceSelected(choice) {
    switch(this.activeTab) {
      case 'projects':
        return this.projectSelected(choice);
      case 'documents':
        return this.documentSelected(choice);
      case 'sections':
        return this.sectionSelected(choice);
      case 'options':
        return this.optionSelected(choice);       
      default:
        console.log('choiceSelected', choice);
        return 'name';
    }
  }

  addNewOfChoice() {
    let name = this.formCreateResource.value.name;
    switch(this.activeTab) {
      case 'projects':
        this.templateFacade.createProject({projectName: name} as Project)
        break;
      case 'documents':
        this.templateFacade.createDocument(this.selectedProject.projectId, {documentName: name} as Document)
        break;
      case 'sections':
        let order = this.formCreateResource.value.order;
        this.templateFacade.createSection(this.selectedProject.projectId, this.selectedDocument.documentId, {sectionName: name, sectionOrder: order} as Section)
        break;
      case 'options':
        this.templateFacade.createOption(this.selectedProject.projectId, this.selectedDocument.documentId, this.selectedSection.sectionId, {optionName: name} as Option)
        break;
      default:
        console.log('addNewOfChoice', 'default');
        break;
    }
    this.hideNewElementModal();
  }

  showNewElementModal() {
    this.modal.nativeElement.classList.add('is-active');
  }

  hideNewElementModal() {
    this.modal.nativeElement.classList.remove('is-active');
    this.formCreateResource.reset();
  }

  deleteResourceOfChoice() {
    let resource = this.formDeleteResource.value.resource;
    switch(this.activeTab) {
      case 'projects':
        this.templateFacade.deleteProject((resource as Project).projectId)
        this.selectedProject = null;
        this.selectedDocument = null;
        this.selectedSection = null;
        this.selectedOption = null;
        break;
      case 'documents':
        this.templateFacade.deleteDocument(this.selectedProject.projectId, (resource as Document).documentId)
        this.selectedDocument = null;
        this.selectedSection = null;
        this.selectedOption = null;
        break;
      case 'sections':
        this.templateFacade.deleteSection(this.selectedProject.projectId, this.selectedDocument.documentId, (resource as Section).sectionId)
        this.selectedSection = null;
        this.selectedOption = null;
        break;
      case 'options':
        this.templateFacade.deleteOption(this.selectedProject.projectId, this.selectedDocument.documentId, this.selectedSection.sectionId, (resource as Option).optionId)
        this.selectedOption = null;
        break;
      default:
        console.log('deleteResourceOfChoice', 'default');
        break;
    }
    this.hideDeleteElementModal();
  }

  showDeleteElementModal() {
    this.deleteModal.nativeElement.classList.add('is-active');
  }

  hideDeleteElementModal() {
    this.deleteModal.nativeElement.classList.remove('is-active');
    this.formDeleteResource.reset();
  }

  showEditElementModal() {
    this.editModal.nativeElement.classList.add('is-active');
  }

  hideEditElementModal() {
    this.editModal.nativeElement.classList.remove('is-active');
    this.formEditResource.reset();
  }

  editResourceOfChoice() {
    let resource = this.formEditResource.value.resource;
    let name = this.formEditResource.value.name ? this.formEditResource.value.name : resource[this.getChoiceSelector()];
    switch(this.activeTab) {
      case 'projects':
        this.templateFacade.modifyProject(({...resource, projectName:name} as Project))
        this.selectedProject = null;
        this.selectedDocument = null;
        this.selectedSection = null;
        this.selectedOption = null;
        break;
      case 'documents':
        this.templateFacade.modifyDocument(this.selectedProject.projectId, ({...resource, documentName: name} as Document))
        this.selectedDocument = null;
        this.selectedSection = null;
        this.selectedOption = null;
        break;
      case 'sections':
        name = this.formEditResource.value.name ? this.formEditResource.value.name : resource.sectionName;
        let order = this.formEditResource.value.order ? this.formEditResource.value.order : resource.sectionOrder;
        this.templateFacade.modifySection(this.selectedProject.projectId, this.selectedDocument.documentId, ({...resource, sectionName: name, sectionOrder: order} as Section))
        this.selectedSection = null;
        this.selectedOption = null;
        break;
      case 'options':
        this.templateFacade.modifyOption(this.selectedProject.projectId, this.selectedDocument.documentId, this.selectedSection.sectionId, ({...resource, optionName: name} as Option))
        this.selectedOption = null;
        break;
      default:
        console.log('editResourceOfChoice', 'default');
        break;
    }
    this.hideEditElementModal();
  }

  getProjects() {
    return this.projectsAndDocs ? this.projectsAndDocs.map(s => s.project) : [];
  }

  getDocuments() {
    return this.projectsAndDocs && this.selectedProject? this.projectsAndDocs
                    .find(s=> s.project.projectName === this.selectedProject.projectName)
                    .documents : [];
  }
  
  projectSelected(project: Project) {
    if(!project){return;}
    this.selectedProject = project;
    this.selectedDocument = null;
    this.selectedSection = null;
    this.selectedOption = null;
    this.setActiveTab('documents');
  }

  documentSelected(document: Document) {
    if(!document){return;}
    this.selectedDocument = document;
    this.templateFacade.loadSections(this.selectedProject.projectId ,document.documentId)
    this.templateFacade.loadTree(this.selectedProject.projectId ,document.documentId);
    this.selectedSection = null;
    this.selectedOption = null;
    this.setActiveTab('sections');
  }

  sectionSelected(section: Section) {
    if(!section){return;}
    this.selectedSection = section;
    this.templateFacade.loadOptions(this.selectedDocument.documentId, section.sectionId);
    this.selectedOption = null;
    this.setActiveTab('options');
  }

  optionSelected(option: Option) {
    if(!option){return;}
    this.selectedOption = option;
    this.changeDetection.detectChanges();
  }

  saveOptionTemplate(optionTemplate: string) {
    this.isSaving = true;
    this.templateFacade.modifyOption(
                          this.selectedProject.projectId,
                          this.selectedDocument.documentId,
                          this.selectedSection.sectionId,
                          {...this.selectedOption, optionTemplate: optionTemplate});
  }

  createForm() {
    this.formCreateResource = new FormGroup({
      name: new FormControl('', Validators.required),
      order: new FormControl(''),
    });
    this.formDeleteResource = new FormGroup({
      resource: new FormControl('', Validators.required),
    });
    this.formEditResource = new FormGroup({
      resource: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      order: new FormControl('', Validators.pattern(/^-?(0|[1-9]\d*)?$/)),
    });
  }

  move(input, from, to) {
    let numberOfDeletedElm = 1;
  
    const elm = input.splice(from, numberOfDeletedElm)[0];
  
    numberOfDeletedElm = 0;
  
    input.splice(to, numberOfDeletedElm, elm);
    return input;
  }
}

const comparatorByField = (a: any, b:any, field:string):number => {
  if(a[field] > b[field]){return 1;} 
  if(a[field] < b[field]){ return -1}
  if(a[field] == b[field]){ return 0}
};