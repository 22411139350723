<div class="layout">
    <div class="left-white">
        <h1 class="thin-title" translate>{{'login.welcome'}}</h1>    
        <h2 class="thin-subtitle" translate>{{'login.loginSubtitle'}}</h2>
        <div class="content">
            
            <div *ngIf="loginError" class='error-box' translate>{{'login.error'}}</div>

            <form [formGroup]="form"  (ngSubmit)="login()">
                <div class="field">
                    <div class="control">
                    <input class="input" type="email" placeholder="{{'form.email' |translate}}" formControlName="username" autofocus="">
                    </div>
                </div>
                <div *ngIf="form.controls.username?.invalid && (form.controls.username?.dirty || form.controls.username?.touched)" class="message is-danger">
                    <div class="message-body" translate><strong >{{'form.error' | translate}}:</strong> {{'form.wrongEmail'}}</div>
                </div>
                <div class="field" style="position: relative;">
                 <div class="control  has-icons-right">
                        <input class="input" [type]="hidePassword ? 'password' : 'text'" placeholder="{{'form.password' |translate}}" formControlName="password">
                    </div>
                    <div class="eye" (click)="toggleRevealPassword()" >
                        <img *ngIf="hidePassword" width="25px" src="assets/icons/eye-solid.svg"/>
                        <img *ngIf="!hidePassword" width="25px" src="assets/icons/eye-slash-solid.svg"/>
                    </div>
                </div>
                <div class="field">
                    <a  class="" href='/forgot-password' translate>
                        {{'navigation.recoverPassword'}}
                    </a>
                </div>
                <button class="button mt-3 is-block is-success is-fullwidth"
                    type="submit"
                    [disabled]="form.invalid"
                    translate
                    >
                    {{'login.logIn'}}
                </button>
            </form>
            <div style="text-align: 'right'; position: absolute; bottom: 3rem;">
                <a  href='/forget-me' translate>
                    {{'navigation.deleteAccount'}}
                </a>
            </div>
        </div>

    </div>

    <div class="right-color gradient-background">
        <h1 class="thin-title white-color" translate>{{'login.signUpInvitation'}}</h1>
        <h2 class="thin-subtitle white-color" translate>{{'login.signupSubtitle'}}</h2>
        

        <div style="width: '60%'; text-align: 'center'; margin-top: 1rem;">
                <a  class="alternative-button" href='/signup' translate>
                    {{'login.signUp'}}
                </a>
            </div>
        <div class='diagonal-div'></div>
    
    </div>
    
</div>