import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionEditorComponent } from './option-editor/option-editor.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteInputModule } from '../common-lib/autocomplete-input/autocomplete-input.module';
import { RouterModule } from '@angular/router';
import { ManageProjectsComponent } from './manage-projects/manage-projects.component';
import { TranslateModule } from '@ngx-translate/core';
import { TiptapComponent } from './editor/tiptap.component';
import { MenuComponent } from './editor/menu/menu.component';
import { MenuItemComponent } from './editor/menu/menuitem.component';
import { ProjectTreeComponent } from './manage-projects/project-tree/project-tree.component';
import { TemplateEditorComponent } from './manage-projects/template-editor/template-editor.component';
import { DirectivesModule } from '../common-lib/directives/directives.module';
import { PlansComponent } from './subscription/plans/plans.component';
import { PreferenceThumbnailComponent } from './option-editor/preference-thumbnail/preference-thumbnail.component';

@NgModule({
  declarations: [TiptapComponent, MenuComponent, MenuItemComponent, OptionEditorComponent, HomeComponent, ManageProjectsComponent, ProjectTreeComponent, TemplateEditorComponent, PlansComponent, PreferenceThumbnailComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteInputModule,
    DirectivesModule,
    TranslateModule,
  ]
})
export class TemplatesModule { }
