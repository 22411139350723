import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "@services/auth/auth.guard";
import { ProfileDetailsComponent } from "../profile/profile-details/profile-details.component";
import { HomeComponent } from "../templates/home/home.component";
import { ManageProjectsComponent } from "../templates/manage-projects/manage-projects.component";
import { TemplateEditorComponent } from "../templates/manage-projects/template-editor/template-editor.component";
import { OptionEditorComponent } from "../templates/option-editor/option-editor.component";
import { MainComponent } from "./main/main.component";
import { PlansComponent } from "../templates/subscription/plans/plans.component";

const routes: Routes = [
    {
      path: '',
      component: MainComponent,
      canActivate: [AuthGuard],
      children: [
        { path: '', component: HomeComponent },
        { path: 'settings', component: ProfileDetailsComponent },
        { path: 'edit-document/:projectId/:userId/:userDocumentId', component: OptionEditorComponent },
        { path: 'manage-projects', component: ManageProjectsComponent },
        { path: 'template-editor', component: TemplateEditorComponent },
        { path: 'plans', component: PlansComponent },
      ],
    },
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class LayoutRoutingModule {}