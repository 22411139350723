import { Action } from '@ngrx/store';

export enum StateActionsType {
  ResetStoreType = '[State] Reset Store',
}

export class ResetStore implements Action {
  readonly type = StateActionsType.ResetStoreType;
}

export type UserActions = ResetStore;
