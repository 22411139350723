import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { UserAuthModel } from '@services/auth/models/user-auth.model';
import { UserModel } from '@services/user/user.model';

import { RolesEnum } from '@services/auth/models/roles.enum';

import { JwtPayloadData } from './models/jwtpayload';

import jwt_decode from "jwt-decode";
import { USER_LOGIN_SERVICE_BASE_URL } from 'src/app/config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticatedObservable: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {
    this.isAuthenticatedObservable.next(false);
  }

  public getToken(): string {
    return localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).token.access_token;
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  get getRefreshToken() {
    return localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).token.refresh_token;
  }


  public isAuthenticated(): boolean {
    // get the token
    const token = this.token;
    // return a boolean reflecting
    // whether or not the token is expired
    const res = !!token;
    this.isAuthenticatedObservable.next(res);
    return res;
  }

  public getRoles(): RolesEnum[] {
    return localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).roles;
  }

  doLogout() {
    return this.http.get<void>(USER_LOGIN_SERVICE_BASE_URL + '/logout').pipe(
      map(() => {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        this.isAuthenticatedObservable.next(false);
      })
    );
  }

  doLogin(username: string, password: string): Observable<UserModel> {
    return this.http
      .post<UserAuthModel>(USER_LOGIN_SERVICE_BASE_URL + '/login', {
        username,
        password,
      })
      .pipe(
        map((user) => {
          localStorage.setItem('token', user.access_token);
          const userObject = this.getUserFromToken();
          userObject.token = user;
          localStorage.setItem('currentUser', JSON.stringify(userObject));
          this.isAuthenticatedObservable.next(true);
          return userObject;
        })
      );
  }

  doRegister(name: string, lastname: string, email: string, password: string, role: string, locale: string) {
    return this.http
      .post<any>(USER_LOGIN_SERVICE_BASE_URL + '/signup', {
        firstName: name,
        lastName: lastname,
        email,
        username: email,
        password,
        roles: [role],
        locale,
      })
      .pipe(
        map((user) => {
          console.log('User signed up', user);
          return user;
        })
      );
  }

  doRegisterOnlyEmail(email: string, password: string, locale: string) {
    return this.http
      .post<any>(USER_LOGIN_SERVICE_BASE_URL + '/signup', {
        firstName: email,
        email,
        username: email,
        password,
        locale,
        roles: [RolesEnum.User],
      })
      .pipe(
        map((user) => {
          console.log('User signed up with email', user);
          return user;
        })
      );
  }

  patchUserInformation(email: string, firstName: string, lastName: string, role: string) {
    return this.http
      .patch<any>(USER_LOGIN_SERVICE_BASE_URL + '/user', {
        username: email,
        email,
        password: '',
        firstName,
        lastName,
        roles: [role],
      })
      .pipe(
        map((user) => {
          console.log('User signed up with email', user);
          return user;
        })
      );
  }

  forgotPassword(email: string) {
    return this.http
      .post<any>(USER_LOGIN_SERVICE_BASE_URL + '/reset-password', {
        email,
      })
      .pipe(
        map((res) => {
          console.log('Recover password action sent');
          return res;
        })
      );
  }

  forgetMe(email: string) {
    return this.http
      .post<any>(USER_LOGIN_SERVICE_BASE_URL + '/forget-me', {
        email,
      })
      .pipe(
        map((res) => {
          console.log('Forget Account action sent');
          return res;
        })
      );
  }

  resendVerificationEmail(email: string) {
    return this.http
      .post<any>(USER_LOGIN_SERVICE_BASE_URL + '/resend-verification-email', {
        email,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  refreshToken() {
    return this.http.get(USER_LOGIN_SERVICE_BASE_URL + '/refresh', {responseType: 'text'});
  }

  getUserFromToken(): UserModel {
    if (this.token) {
      const payload = { ...jwt_decode(this.token) } as JwtPayloadData;
      return {
        username: payload.name ? payload.name : (payload.email? payload.email : ''),
        roles: payload.realm_access ? (payload.realm_access.roles as RolesEnum[]) : [RolesEnum.User],
        userId: payload.sub,
        email: payload.email,
        email_verified: payload.email_verified
      };
    }
  }


}
