import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

import { ORGANIZATION_ID, SUBSCRIPTION_SERVICE_BASE_URL, USER_LOGIN_SERVICE_BASE_URL } from 'src/app/config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private URLS_NO_TOKEN: string[] = [
    USER_LOGIN_SERVICE_BASE_URL + '/login',
    USER_LOGIN_SERVICE_BASE_URL + '/signup',
    USER_LOGIN_SERVICE_BASE_URL + '/forget-me',
    USER_LOGIN_SERVICE_BASE_URL + '/reset-password',
    USER_LOGIN_SERVICE_BASE_URL + '/resend-verification-email',
    SUBSCRIPTION_SERVICE_BASE_URL+ `/plans?orgId=${ORGANIZATION_ID}`,
  ];

  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.URLS_NO_TOKEN.includes(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.token}`,
        },
      });
    }
    return next.handle(request);
  }
}
