import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ForgetMe, ForgotPassword, LoginUser, LogoutCurrentUser, RefreshToken, RefreshTokenInterval, RefreshTokenSuccess, SetCurrentUser, SignUpUser, UserActionsType, UserCommonError } from '@services/user/user.actions';
import { ResetStore } from '../../store/state.actions';

import { AuthService } from '@services/auth/auth.service';
import { Store } from '@ngrx/store';
import { UserState } from './user.reducer';

@Injectable()
export class UserEffects {
  private refreshInterval;
  private intervalDuration = 6000 // Every Minute

  @Effect()
  loginUser = this.actions.pipe(
    ofType(UserActionsType.LoginUserType),
    mergeMap((action: LoginUser) =>
      this.authService.doLogin(action.username, action.password).pipe(
        map((user) => {
       //   this.startRefreshInterval();
          return new SetCurrentUser(user)
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.router.navigate(['/verify-email']);
            return;
          }
          return of(new UserCommonError(err))
          }
        )
      )
    )
  );

  @Effect()
  logoutUser = this.actions.pipe(
    ofType(UserActionsType.LogoutUserType),
    mergeMap(() =>
      this.authService.doLogout().pipe(
        map(() => {
          clearInterval(this.refreshInterval);
          this.router.navigate(['/login']);
          localStorage.removeItem('currentUser');
          localStorage.removeItem('token');
          return new ResetStore();
        }),
        catchError((err) => {
          clearInterval(this.refreshInterval);
          localStorage.removeItem('currentUser');
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
          return of(new ResetStore())
        })
      )
    )
  );

  @Effect()
  refreshToken = this.actions.pipe(
    ofType(UserActionsType.RefreshTokenType),
    mergeMap((action: RefreshToken) => {
      return this.authService.refreshToken().pipe(
        map((token) => {
          return new RefreshTokenSuccess(token)
        }),
        catchError((err: HttpErrorResponse) => {
          console.log("mergeMap pf refreshToken", err)
          if (err.status == 401) {
            return of(new LogoutCurrentUser());
          }
          return of(new UserCommonError(err))
        })
      )
    }
    )
  );

  @Effect()
  refreshTokenInterval = this.actions.pipe(
    ofType(UserActionsType.RefreshTokenIntervalType),
    mergeMap((action: RefreshTokenInterval) => {
      this.startRefreshInterval();
      return of(new RefreshToken());
    })
    
  );

  @Effect()
  forgotPassword = this.actions.pipe(
    ofType(UserActionsType.ForgotPasswordType),
    mergeMap((action: ForgotPassword) =>
      this.authService.forgotPassword(action.email).pipe(
        map(() => {
          this.router.navigate(['/login']);
          return new ResetStore();
        }),
        catchError((err) => of(new UserCommonError(err)))
      )
    )
  );

  @Effect()
  forgetMe = this.actions.pipe(
    ofType(UserActionsType.ForgetMeType),
    mergeMap((action: ForgetMe) =>
      this.authService.forgetMe(action.email).pipe(
        map(() => {
          this.router.navigate(['/login']);
          return new ResetStore();
        }),
        catchError((err) => { 
          this.router.navigate(['/login']);
          localStorage.removeItem('currentUser');
          localStorage.removeItem('token');
          return of(new UserCommonError(err))
        })
      )
    )
  );

  @Effect()
  signUpUser = this.actions.pipe(
    ofType(UserActionsType.SignUpUserType),
    mergeMap((action: SignUpUser) =>
      this.authService.doRegisterOnlyEmail(action.email, action.password, action.locale).pipe(
        map(() => {
          this.router.navigate(['/verify-email']);
          return new ResetStore();
        }),
        catchError((err) => of(new UserCommonError(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  userCommonError = this.actions.pipe(
    ofType(UserActionsType.UserCommonErrorType),
    mergeMap((error: HttpErrorResponse) => {
      return of(error);
    })
  );

  startRefreshInterval() {
    if(!this.refreshInterval) {
      console.log("Setting the interval for refresh")
 
      this.refreshInterval = setInterval(() => this.userStore.dispatch(new RefreshToken()), this.intervalDuration);
    } else {
      console.log("Do Nothing on refresh, interval is set")
    }

  }

  constructor(
    private readonly userStore: Store<UserState>,
    private actions: Actions, 
    private readonly authService: AuthService, 
    private readonly router: Router) {}
}
