import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TEMPLATE_SERVICE_BASE_URL, USER_DOCUMENTS_SERVICE_BASE_URL } from 'src/app/config';
import { LoadOptionsResponse, LoadSectionsResponse, ProjectDocumentsResponse, RecentDocumentsResponse, UserDocument, Option, Project, Section, Document, LoadTreeResponse } from './templates.model';

@Injectable({
    providedIn: 'root',
  })
  export class TemplateService {
  
    constructor(private http: HttpClient) {}

    getProjectDocuments() {
        return this.http.get<ProjectDocumentsResponse>(TEMPLATE_SERVICE_BASE_URL+ "/");
    }

    loadTree(documentId: number) {
        return this.http.get<LoadTreeResponse>(
            `${TEMPLATE_SERVICE_BASE_URL}/${documentId}/tree`);
    }

    loadSections(documentId: number) {
        return this.http.get<LoadSectionsResponse>(
            `${TEMPLATE_SERVICE_BASE_URL}/${documentId}/sections`);
    }

    loadOptions(documentId: number, sectionId: number) {
        return this.http.get<LoadOptionsResponse>(
            `${TEMPLATE_SERVICE_BASE_URL}/${documentId}/sections/${sectionId}/options`);
    }

    loadRecentDocuments(userId: string) {
        return this.http.get<RecentDocumentsResponse>(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments`);
    }

    fetchRecentDocument(userId: string, userDocumentId: number) {
        return this.http.get<UserDocument>(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/${userDocumentId}`);
    }

    createUserDocuments(userId: string, userDocument: UserDocument) {
        return this.http.post<UserDocument>(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments`,
        {...userDocument, userId: userId});
    }

    modifyUserDocuments(userId: string, userDocument: UserDocument) {
        return this.http.patch<UserDocument>(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments`,
        {...userDocument, userId: userId});
    }

    deleteUserDocuments(userId: string, userDocumentId: number) {
        return this.http.delete(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/${userDocumentId}`);
    }

    // create
    createProject(request: Project) {
        return this.http.post<Project>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects`,
            request
        );
    }

    createDocument(projectId: number, request: Document) {
        return this.http.post<Document>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents`,
            {   ...request,
                projectId: projectId
            }
        );
    }

    createSection(projectId: number, documentId: number, request: Section) {
        return this.http.post<Section>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}/sections`,
            {...request, documentId: documentId}
        );
    }

    createOption(projectId: number, documentId: number, sectionId: number, request: Option) {
        return this.http.post<Option>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}/sections/${sectionId}/options`,
            {...request, optionTemplate: '', sectionId: sectionId});
    }

    // Modify
    modifyProject(patch: Project) {
        return this.http.patch<Project>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects`,
            patch
        );
    }

    modifyDocument(projectId: number, patch: Document) {
        return this.http.patch<Document>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents`,
            patch
        );
    }

    modifySection(projectId: number, documentId: number, patch: Section) {
        return this.http.patch<Section>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}/sections`,
            patch
        );
    }
    modifyOption(projectId: number, documentId: number, sectionId: number, patch: Option) {
        return this.http.patch<Option>(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}/sections/${sectionId}/options`,
            patch);
    }
    // Delete

    deleteProject(projectId: number) {
        return this.http.delete(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}`);
    }

    deleteDocument(projectId: number, documentId: number) {
        return this.http.delete(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}`);
    }

    deleteSection(projectId: number, documentId: number, sectionId: number) {
        return this.http.delete(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}/sections/${sectionId}`);
    }
    deleteOption(projectId: number, documentId: number, sectionId: number, optionId: number) {
        return this.http.delete(
            `${TEMPLATE_SERVICE_BASE_URL}/projects/${projectId}/documents/${documentId}/sections/${sectionId}/options/${optionId}`);
    }

    // PDF
    generatePDFDocument(userId: string, userDocumentId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/${userDocumentId}/pdf`, {headers: headers, responseType: 'blob'});
    }

    // Preferences
    loadPreferences(userId: string) {
        return this.http.get(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/preferences`);
    }

    createPreferences(userId: string, preferences) {
        return this.http.post(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/preferences`, {...preferences});
    }

    updatePreferences(userId: string, preferences) {
        return this.http.post(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/preferences/${preferences.id}`, {...preferences});
    }

    deletePreferences(userId: string, preferenceId: string) {
        return this.http.delete(`${USER_DOCUMENTS_SERVICE_BASE_URL}/${userId}/userDocuments/preferences/${preferenceId}`);
    }
}