import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserFacade } from '@services/user/user.facade';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;

  constructor(private userfacade: UserFacade) { }

  ngOnInit(): void {
    this.createForm();
  }

  forgotPassword() {
    this.userfacade.forgotPasword(this.form.value.email);
  }

  private createForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
}
