<div class="verify">
  <div class="text-group">
      <h1 class="thin-title" translate>{{'verify.title'}}</h1>    
      <h2 class="thin-subtitle" translate>{{'verify.subtitle'}}</h2>
  </div>

  <div class="steps-container">
      <div class='step'>
          <div class='step-number'>1</div>
          <h3 class='step-title' translate>{{'verify.verifyStep1Title'}}</h3>
          <p class='step-instructions' translate>{{'verify.verifyStep1Instructions'}}</p>
      </div>

      <div class='step'>
          <div class='step-number'>2</div>
          <h3 class='step-title' translate>{{'verify.verifyStep2Title'}}</h3>
          <p class='step-instructions' translate>{{'verify.verifyStep2Instructions'}}</p>
      </div>

      <div class='step'>
          <div class='step-number'>3</div>
          <h3 class='step-title' translate>{{'verify.verifyStep3Title'}}</h3>
          <p class='step-instructions' translate>
              {{'verify.verifyStep3Instructions'}}
              <span class='text text-highlight'>
                <a *ngIf="!isLogged()" href="/login" translate>{{'login.logIn'}}</a>
                <button *ngIf="isLogged()" (click)="logout()" translate>{{'navigation.signOut'}}</button>
            </span>
          </p>
          
      </div>
  </div>

</div>