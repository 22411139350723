import { mergeAttributes, Node } from '@tiptap/core'

export interface PlaceholderOptions {
    HTMLAttributes: Record<string, any>,
  }

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
      placeholder: {
        /**
         * Add a placeholder
         */
        setPlaceholder: (options: { id: string, placeholder?: string, title?: string }) => ReturnType,
      }
    }
  }

export const PlaceholderExtension = Node.create<PlaceholderOptions>({
    name: 'placeholder',
    group: 'inline',
    inline: true,
    draggable: true,
    selectable: true,
    

    addOptions() {
        return {
          HTMLAttributes: {},
        }
    },
    
    addAttributes() {
        return {
            id: {
                default: null
            },
            placeholder: {
                default: null
            },
            title: {
                default: null
            },
            value: {
              default: null
          }
        }
    },

    parseHTML() {
        return [{
            tag: 'input[id][title][placeholder]'
        }]
    },
    renderHTML({ HTMLAttributes }) {
        return ['input', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },
    renderText() {
        return `{{ ${this.options.HTMLAttributes.placeholder} }}`
    },

    addCommands() {
        return {
            setPlaceholder: options => ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            })
          },
        }
      },

  })
  