import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import PlaceholderExtension from './menu/tiptap-extension';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import BulletList from '@tiptap/extension-bullet-list'

const PLACEHOLDER_REGEXP = /<input.*?>/g;
const PLACEHOLDER_ID_REGEXP = /id="(.*?)"/;
const PLACEHOLDER_VALUE_REGEXP = /value="(.*?)"/;
const PLACEHOLDER_TITLE_REGEXP = /title="(.*?)"/;
const PLACEHOLDER_PLACEHOLDER_REGEXP = /placeholder="(.*?)"/;

@Component({
  selector: 'tiptap-editor',
  template: `
        <div class="mt-4 columns">
          <div class="column is-8">
            <div class="tiptap-editor">
              <tiptap-menu-editor [editor]="editor" [hasPlaceholderButton]="hasPlaceholderButton" [isEditingTable]="isEditingTable"></tiptap-menu-editor>
              <div class="tiptap-editor__content" style="height:80%; max-height:700px" ></div>                
              <div class="tiptap-editor__footer">
                <div class="tiptap-editor__status tiptap-editor__status--{{status}}">
                </div>
                <div class="is-fullwidth" style="display:flex; justify-content: flex-end">
                 <button (click)="save()"
                      class="button is-rounded is-block is-normal is-primary"
                      [ngClass]="{'is-loading': isSaving === true }"
                      translate
                      >{{'form.save'}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-4 dark-box">
              <p>placeholders of the options here</p>
              <div *ngFor="let placeholder of placeholders">
                <p><strong>{{placeholder.title}}</strong></p>
                <p class="placeholder-description"><em> {{placeholder.placeholder}}</em></p>
                <input id="{{placeholder.id}}" *ngIf="!hasPlaceholderButton" [ngModel]="placeholder.value" (ngModelChange)="updatePlaceholder(placeholder, $event)"/>
              </div>  
          </div>
        </div>    `,
  styleUrls: ['./menu/menu.style.scss']

})
export class TiptapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public initialContent: string;
  @Input() public isSaving: boolean;
  @Input() public hasPlaceholderButton: boolean = false;
  @Output() public saveContent = new EventEmitter()
  public isEditingTable: boolean = false;
  public actualContent: string = "";
  public editor: Editor;
  public status: string = 'connected'; // or connecting
  public placeholders: Array<{ title: string, placeholder: string, id:string, value: string }> = []


  ngOnInit(): void {
    this.actualContent = this.initialContent;
    this.initializeEditor(this.actualContent)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialContent && !changes.initialContent.firstChange) {
      this.actualContent = changes.initialContent.currentValue;
      this.editor.destroy();
      this.initializeEditor(this.actualContent);
    }
  }

  ngOnDestroy(): void {
    this.save();
  }

  initializeEditor(content: string) {
    this.editor = new Editor({
      element: document.querySelector('.tiptap-editor__content'),
      extensions: [
        StarterKit.configure({
          // The Collaboration extension comes with its own history handling
          history: false,
        }),
        PlaceholderExtension,
        Table.configure({
          resizable: true,
          cellMinWidth: 50,
          allowTableNodeSelection: true
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      content: content,
    })
    
    
    this.editor.on('selectionUpdate', ({ editor }) => {        
      let tc = this.editor.getAttributes('tableCell')
      let th = this.editor.getAttributes('tableHeader')
      // Just check that any property exists on the resulting object
      this.isEditingTable = !!tc.colspan || !!th.colspan 
    })


    this.editor.on('update', ({ editor }) => {
      this.populatePlaceholders(editor.getHTML())
    })

    this.populatePlaceholders(content);
  }

  save() {
    this.saveContent.emit(this.editor.getHTML());
  }

  populatePlaceholders(template: string) {
    this.placeholders = [...template.matchAll(PLACEHOLDER_REGEXP)].map(e => {
      return {
        title: e[0].match(PLACEHOLDER_TITLE_REGEXP)[1],
        placeholder: e[0].match(PLACEHOLDER_PLACEHOLDER_REGEXP)[1],
        id: e[0].match(PLACEHOLDER_ID_REGEXP)[1],
        value: e[0].match(PLACEHOLDER_VALUE_REGEXP)? e[0].match(PLACEHOLDER_VALUE_REGEXP)[1]: '',
      }
    })
  } 

  updatePlaceholder(placeholder:{ title: string, placeholder: string, id:string, value: string }, value: string) {
    const placeholderRegex = new RegExp(`<input.{0,50}id="${placeholder.id}".*?>`)
    console.log(placeholder.id, value, placeholderRegex)

    let contentWithoutReplacement = this.editor.getHTML();
    const newPlaceholder = `<input id="${placeholder.id}" title="${placeholder.title}" placeholder="${placeholder.placeholder}" value="${value}"/>`
    let contentReplaced = contentWithoutReplacement.replace(placeholderRegex, newPlaceholder)
    this.editor.commands.setContent(contentReplaced)
  }

}