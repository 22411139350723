import { Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { SectionTree, Document } from '@services/templates/templates.model';

@Component({
    selector: 'project-tree',
    templateUrl: './project-tree.component.html',
    styleUrls: ['./project-tree.component.scss']
  })
  export class ProjectTreeComponent {
    @Input() public document: Document;
    @Input() public tree: SectionTree[];
    @Output() itemClicked: EventEmitter<{sectionId: number; optionId:number}> = new EventEmitter<{sectionId: number; optionId:number}>();
    @ViewChild('documentTree') documentTree: ElementRef;

    clickedItem = null;

    clickItem(itemId) {
      this.clickedItem = itemId;
      let parts = itemId.split('-');
      if(parts.length > 1) {
        this.itemClicked.emit({
          sectionId: parseInt(parts[0]),
          optionId:  parseInt(parts[1])
        });
      } else {
        this.itemClicked.emit({sectionId: parseInt(parts[0]), optionId: undefined});
      }
    }

    zoomIn() {
      let zoom = this.documentTree.nativeElement.style.zoom.split('%')[0];
      console.log('zoom', zoom)
      zoom = Math.min(100, parseInt(zoom) + 25);
      console.log('zoom aumented', zoom)
      this.documentTree.nativeElement.setAttribute('style', `zoom: ${zoom}%;`);
    }

    zoomOut() {
      let zoom = this.documentTree.nativeElement.style.zoom.split('%')[0];
      console.log('zoom', zoom)
      zoom = Math.max(0, parseInt(zoom) - 25);
      console.log('zoom reduced', zoom)
      this.documentTree.nativeElement.setAttribute('style', `zoom: ${zoom}%;`);
    }

  }