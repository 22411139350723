import { Component, OnInit } from "@angular/core";
import { Subscriber, Subscription } from "@services/subscription/subscription.model";
import { SubscriptionService } from "@services/subscription/subscription.service";


@Component({
    selector: 'subscription-management',
    templateUrl: './subscription-management.component.html',
    styleUrls: ['./subscription-management.component.scss']
  })
  export class SubscriptionManagementComponent implements OnInit {
    public myUserId: string;
    public subscriptions: Array<Subscription>;
    public subscribers: Map<string, Array<Subscriber>> = new Map();
    public subscriberId: string;
    public subscriptionErrors: string;

    constructor(private subscriptionService: SubscriptionService){}

    ngOnInit(): void {
        let currentUser = localStorage.getItem('currentUser')
        if(currentUser) {
            this.myUserId = JSON.parse(currentUser).userId;
        }

        this.querySubscriptions();
    }

    querySubscriptions() {
        this.subscriptionService.querySubscriptions().subscribe(subscriptions =>{
            this.subscriptions = subscriptions;
            this.subscriptions.forEach(s => {
                this.subscriptionService.querySubscribersOfSubscription(s.id).subscribe(subscribers =>{
                    this.subscribers.set(s.id, subscribers);
                })
            })
        })
    }

    addSubscriberToSubscription() {
        this.subscriptionErrors="";
        this.subscriptionService.addSubscriberToSubscription(this.subscriptions[0].id, this.subscriberId)
            .subscribe(
                res => {
                    this.querySubscriptions();
                    this.subscriberId= ""
                },
                 err => {
                    if(err.status == 403) {
                        this.subscriptionErrors = "ERROR";
                    }
                }
            )
    }

    setMembershipMembership(subscriberId: string, isActive: boolean) {
        this.subscriptionErrors="";
        this.subscriptionService.updateStateOfSubscriber(this.subscriptions[0].id, subscriberId, isActive)
            .subscribe(
                res => {
                    this.querySubscriptions();
                },
                 err => {
                    if(err.status == 403) {
                        this.subscriptionErrors = "ERROR"
                    }
                }
            )

    }

    getSubscribersInFirstSubscription() {
        return this.getSubscribers(this.subscriptions[0].id)
    }

    getSubscribers(subscriptionId: string) {
        return this.subscribers.has(subscriptionId) ? 
            this.subscribers.get(subscriptionId).filter(s => s.memberId != this.myUserId) 
            : [];
    }

    subscribersCount(subscriptionId: string): number {
        return this.subscribers.has(subscriptionId) ? 
            this.subscribers.get(subscriptionId).filter(s => s.isActive).length
            : 0;
    }


  }