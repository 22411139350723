import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { UserFacade } from '@services/user/user.facade';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  isLogged() {
    return this.authService.isAuthenticated();
  }
  logout() {
    this.authService.doLogout().subscribe();
  }
}
