import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplatesFacade } from '@services/templates/templates.facade';
import { Option, Section, UserDocument, UserDocumentData, UserDocumentDataSection } from '@services/templates/templates.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-option-editor',
  templateUrl: './option-editor.component.html',
  styleUrls: ['./option-editor.component.scss']
})
export class OptionEditorComponent implements OnInit {
  private firstLoad = true;
  public documentId: number;
  public projectId: number;
  public userId: string;
  public userDocument: UserDocument;
  public userDocumentId: number;
  private userDocumentData: UserDocumentData;

  public options: Array<Option>;
  public sections: Array<Section>;
  public selectedSection: Section;
  public selectedOption: Option;
  public userEditedSection: UserDocumentDataSection;

  public content: string;
  public actualContent: String= "";
  public isSaving: boolean = false;
  public preferences = [];


  constructor(
      private readonly route: ActivatedRoute,
      private templateFacade: TemplatesFacade,
      private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.templateFacade.flushState();
    this.userId = this.route.snapshot.params['userId'];
    this.userDocumentId = this.route.snapshot.params['userDocumentId'];
    this.projectId = this.route.snapshot.params['projectId'];

    this.templateFacade.loadUserDocument(this.userId, this.userDocumentId);

    this.templateFacade.userDocument.subscribe(userDocument => {
      setTimeout(() => this.isSaving = false , 1500);
      if(!userDocument){return;}
      this.userDocument = userDocument;
      this.documentId = userDocument.documentId;
      this.userDocumentData = JSON.parse(userDocument.userDocumentData);

      this.templateFacade.loadSections(this.projectId, this.documentId);
    });

    this.templateFacade.sectionsInDocument.subscribe((sections) => {
      if(sections) {
        this.sections = [...sections];
        this.sortSectionByOrder();
        if(this.firstLoad) {
          this.templateFacade.loadOptions(this.documentId, sections[0].sectionId)
          this.selectedSection = this.sections[0];
        }
      }
    });

    this.templateFacade.optionsInSection.subscribe((options) => {
      this.options = options;
      if(this.firstLoad && this.selectedSection) {
        this.loadOptionsOfSection(this.selectedSection);
        this.firstLoad = false;
      }
    });

    this.templateFacade.loadPreferences(this.userId).subscribe(res => {
      this.preferences = res as any[];
       // this.preferences.forEach((preference, idx) => {
       //   this.forms[idx] = this.createForm(preference);
       // });
    })
  }

  selectPreference(preferenceId: string) {
    if(!preferenceId) {return}
    this.templateFacade.modifyUserDocument(this.userId, {
      ...this.userDocument, 
      preferenceId: preferenceId
    });
    this.isSaving = true;

  }

  loadOptionsOfSection(section: Section) {
    this.content = null;
    this.selectedSection = section;  
    // Load template options
    this.templateFacade.loadOptions(this.documentId, section.sectionId)

    // Load data comming from the user data
    this.userEditedSection = this.orElse(this.userDocumentData.sections.find(s => s.sectionId == section.sectionId), 
      {
        sectionId: section.sectionId,
        customSectionName: section.sectionName, 
        sectionOrder: section.sectionOrder 
      } as UserDocumentDataSection); 
    this.selectedOption = this.userEditedSection && this.userEditedSection.optionId? this.getOptionFromUserDocument(this.userEditedSection) : null;
    this.content = this.userEditedSection && this.userEditedSection.optionId? this.userEditedSection.overwrittenTemplate : null;

  }

  selectOption(option: Option) {
    if(!option){return;}
    this.selectedOption = option;
    this.userEditedSection.optionId = option.optionId;
    this.userEditedSection.overwrittenTemplate = option.optionTemplate;
    this.content = option.optionTemplate;
  }

  updateUserDocument(name: NgModel) {
    this.templateFacade.modifyUserDocument(this.userId, {
      ...this.userDocument, 
      userDocumentName: name.value
    });
  }

  updateUserSectionOrder(sectionOrder) {
    this.userEditedSection.sectionOrder = sectionOrder.value;
    this.userEditedSection.sectionId = this.selectedSection.sectionId;
    this.addSectionToUserData(this.userEditedSection);

    this.templateFacade.modifyUserDocument(this.userId,{
      ...this.userDocument, 
      userDocumentData: JSON.stringify(this.userDocumentData)
    });
  }

  updateUserSectionName(sectionName) {
    this.userEditedSection.customSectionName = sectionName.value;
    this.userEditedSection.sectionId = this.selectedSection.sectionId;
    this.addSectionToUserData(this.userEditedSection);

    this.templateFacade.modifyUserDocument(this.userId,{
      ...this.userDocument, 
      userDocumentData: JSON.stringify(this.userDocumentData)
    });
  }

  saveContent(actualContent: string) {
    this.updateSectionInsUserDocument(actualContent);
    this.templateFacade.modifyUserDocument(this.userId,{
      ...this.userDocument, 
      userDocumentData: JSON.stringify(this.userDocumentData)
    });
    this.isSaving = true;
  } 

  getOptionFromUserDocument(uddSection: UserDocumentDataSection) {
    return this.options.find( o => o.optionId === uddSection.optionId!);
  }

  addSectionToUserData(uddSection: UserDocumentDataSection) {
    this.userDocumentData.sections = this.userDocumentData.sections.filter(s => s.sectionId !== uddSection.sectionId);
    this.userDocumentData.sections.push(uddSection);
  }

  private updateSectionInsUserDocument(actualContent: string) {
    this.userEditedSection.overwrittenTemplate = actualContent;
    this.userEditedSection.sectionId = this.selectedSection.sectionId;
    this.userDocumentData.sections = this.userDocumentData.sections.filter(s => s.sectionId !==  this.userEditedSection.sectionId);
    this.userDocumentData.sections.push(this.userEditedSection);
  }

  sortSectionByOrder () {
    let orders: string[] =  this.sections.map( a => a.sectionOrder.split('.').map( n => +n+100000 ).join('.') ).sort()
    .map( a => a.split('.').map( n => +n-100000 ).join('.') )

    let sorted = []
    for (const order of orders) {
      sorted.push(this.sections.find( a => a.sectionOrder == order))
    }
    this.sections= [...new Set(sorted)];
    //return arr;
  };

  private orElse(obj: any | undefined, defaultValue: any) {
    if(obj === undefined) {
      return defaultValue;
    }
    return obj;
  }

  downloadPdf() {
    this.spinner.show();
    this.templateFacade.generatePDFDocument(this.userId, this.userDocumentId).subscribe((data) =>{
      this.spinner.hide();
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL, '_blank');
    },
    () => {
      this.spinner.hide();
      alert("Error generando documento.");
    })
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
