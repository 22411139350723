import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
    selector: 'preference-thumbnail',
    templateUrl: './preference-thumbnail.component.html',
    styleUrls: ['./preference-thumbnail.component.scss']
  })
  export class PreferenceThumbnailComponent implements OnInit, OnChanges {
    @Input() idx: number;
    @Input() preference: any;
    @Input() selectedPreferenceId: string;

    public isSelected = false;

    ngOnInit(): void {
      this.isSelected = this.selectedPreferenceId && this.preference && this.preference.id == this.selectedPreferenceId;
    } 

    ngOnChanges(changes: SimpleChanges): void {
      if (!changes.selectedPreferenceId || changes.selectedPreferenceId.currentValue == changes.selectedPreferenceId.previousValue) {
          return;
        }
        if (changes.selectedPreferenceId.currentValue) {
          this.selectedPreferenceId = changes.selectedPreferenceId.currentValue;
          this.isSelected = this.selectedPreferenceId && this.preference && this.preference.id == this.selectedPreferenceId;
        } 
  }


  }