import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'tiptap-menu-item-editor',
    template: `
            <button class="menu-item"  [ngClass]="{'is-active': item.isActive && item.isActive()}" (click)="item.action()" title="{{item.title}}">
                <i class="ri-{{item.icon}}"></i>
            </button>`,
    styleUrls: ['./menu.style.scss']
  })
  export class MenuItemComponent {
    @Input() public item: {icon:string; title:string, action:()=>void; isActive: ()=>boolean};

  }