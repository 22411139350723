import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TemplatesFacade } from '@services/templates/templates.facade';
import { UserFacade } from '@services/user/user.facade';

const INITIAL_PREFERENCES = {
  nameOrganization: '',
  position: '',
  identificationNumber: '',
  memberNumber: '',
  email: '',
  phoneNumber: '',
  typographyUrl: '',
  typographyName: '',
  typographySize: '',
  typographyColor: '',
  coverBgUrl: '',
  marginLogoUrl: '',
  lang: ''
}

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {

  form: FormGroup;
  forms: FormGroup[] = [];
  userId: string;
  preferences;
  isSaving= false;
  constructor(private templateFacade: TemplatesFacade, private readonly userFacade: UserFacade) { }

  ngOnInit(): void {
    this.forms[0] = this.createForm(INITIAL_PREFERENCES);
    this.form = this.forms[0];

    this.userFacade.currentUser.subscribe((user) => {
      if (user && this.userId!== user.userId) {
        this.userId = user.userId;
        this.templateFacade.loadPreferences(this.userId).subscribe(res => {
          this.preferences = res;
           this.preferences.forEach((preference, idx) => {
              this.forms[idx] = this.createForm(preference);
           });
           this.form = this.forms[0];
        })
      }
    })
  }

  selectForm(index: number) {
    this.form = this.forms[index]
  }

  createPreferences() {
    this.templateFacade.createPreferences(this.userId, INITIAL_PREFERENCES)
      .subscribe(res => {
        this.forms[this.forms.length] = this.createForm(res)
        this.form=this.forms[this.forms.length -1];
      });
  }

  updatePreferences() {
    this.isSaving= true;
    this.templateFacade.updatePreferences(this.userId, this.form.value)
          .subscribe(res => {this.preferences = res; this.isSaving= false;});
  }

  deletePreferences() {
    if (this.forms.length <= 1) {
      // At least one preference should stay.
      return;
    }

    this.templateFacade.deletePreferences(this.userId, this.form.value.id)
        .subscribe(res => {
          this.forms = this.forms.filter(f => f.value.id != this.form.value.id);
          this.form = this.forms[0];
        });
  }

  private createForm(initialPreferences): FormGroup {
    return new FormGroup({
      id: new FormControl(initialPreferences.id, ),
      nameOrganization: new FormControl(initialPreferences.nameOrganization, ),
      position: new FormControl(initialPreferences.position, ),
      identificationNumber: new FormControl(initialPreferences.identificationNumber, ),
      memberNumber: new FormControl(initialPreferences.memberNumber, ),
      email: new FormControl(initialPreferences.email, ),
      phoneNumber: new FormControl(initialPreferences.phoneNumber, ),
      typographyUrl: new FormControl(initialPreferences.typographyUrl, ),
      typographyName: new FormControl(initialPreferences.typographyName, ),
      typographySize: new FormControl(initialPreferences.typographySize, ),
      typographyColor: new FormControl(initialPreferences.typographyColor, ),
      coverBgUrl: new FormControl(initialPreferences.coverBgUrl, ),
      marginLogoUrl: new FormControl(initialPreferences.marginLogoUrl, ),
      lang: new FormControl(initialPreferences.lang, )
    });
  }
}
