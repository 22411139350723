import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@services/auth/auth.guard';
import { ForgetMeComponent } from './login/forget-me/forget-me.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login/login.component';
import { SignupComponent } from './login/signup/signup.component';
import { VerifyEmailComponent } from './login/verify-email/verify-email.component';
import { NoAuthGuard } from './services/auth/no-auth-guard.service';
import { OptionEditorComponent } from './templates/option-editor/option-editor.component';

const routes: Routes = [
  {path: '', canActivate: [AuthGuard], loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule) },
  {path: 'login', canActivate: [NoAuthGuard], component: LoginComponent },
  {path: 'signup', canActivate: [NoAuthGuard], component: SignupComponent },
  {path: 'verify-email', component: VerifyEmailComponent },
  {path: 'forgot-password', canActivate: [NoAuthGuard], component: ForgotPasswordComponent },
  {path: 'forget-me', canActivate: [NoAuthGuard], component: ForgetMeComponent },

  {path: 'editor', canActivate: [AuthGuard], component: OptionEditorComponent },
  { path: '**', canActivate: [AuthGuard], redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
