import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HeaderComponent, MainComponent],
  imports: [
    CommonModule,
    RouterModule,
    LayoutRoutingModule,
    AvatarModule,
    TranslateModule
  ],
  exports: [
    MainComponent,HeaderComponent]
})
export class LayoutModule { }
