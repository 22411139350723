<div  *ngIf="userDocument">
    <form (submit)="updateUserDocument(userDocumentName)">
        <div class="field" style="display:flex; align-items: flex-end;"> 
            <h1 class="second-title mr-2" translate>{{'optionEditor.nameOfDocument'}}</h1>               
            <div class="control mr-2">
                <input class="input projectNameInput" type="text"  #userDocumentName name="userDocumentName"  id="userDocumentName" required value="{{userDocument.userDocumentName}}" />
            </div>
            <div class="control">
                <button type="submit" class="button save-button" translate>{{'form.save'}}</button>
            </div>

        </div>
    </form>
</div>

<div  class="field is-flex is-align-items-center" >
    <h1 class="second-title mr-2" translate>{{'optionEditor.selectTemplate'}}</h1>               
    <div *ngIf="preferences && preferences.length > 0 && userDocument && userDocument.preferenceId" class="is-flex" style="gap:0.5rem;">
        <preference-thumbnail 
        *ngFor="let pf of preferences; let i = index"  
        [preference]="pf"
        [idx]= "i"
        (click)="selectPreference(pf.id)"
        [selectedPreferenceId]="userDocument.preferenceId"
        ></preference-thumbnail>
    </div>

</div>

<div class="mt-1 columns intermediate-section">
        <div class="column is-3">
            <div class="box section-panel">
                <div class="section" *ngFor="let section of sections"
                    (click)="loadOptionsOfSection(section)"
                ><p [ngClass]="{'selected-section': section == selectedSection}"> <strong>{{section.sectionOrder}}</strong> {{section.sectionName}} </p></div>
            </div>
            <button class="button is-fullwidth is-primary" (click)="downloadPdf()"translate>{{'optionEditor.exportToPDF'}}</button>
        </div>
        <div class="column">

            <div class="box section-content">
                <div class="">
                <form (submit)="updateUserSectionName(customSectionName)">
                    <div class="field" style="display:flex; align-items: flex-end;"> 
                        <label class="label mr-2" style="width: 25%;" translate>{{'optionEditor.nameOfSection'}}</label>               
                        <div class="control mr-2">
                            <input class="input projectNameInput" type="text"  
                            #customSectionName name="customSectionName" 
                            id="customSectionName" required 
                            value="{{userEditedSection  && userEditedSection.customSectionName ? userEditedSection.customSectionName: selectedSection ? selectedSection.sectionName : '' }}" />
                        </div>
                        <div class="control">
                            <button type="submit" class="button save-button" translate>{{'form.save'}}</button>
                        </div>
                    </div>
                </form>
                <form *ngIf="false" (submit)="updateUserSectionOrder(sectionOrder)">
                    <div class="field mt-2" style="display:flex; align-items: flex-end;"> 
                        <label class="label mr-2" style="width: 25%;"translate>{{'optionEditor.preferedOrder'}}</label>               
                        <div class="control mr-2">
                            <input class="input projectNameInput" type="text"  
                            #sectionOrder name="sectionOrder" 
                            id="sectionOrder" required 
                            value="{{userEditedSection && userEditedSection.sectionOrder ? userEditedSection.sectionOrder : '' }}" />
                        </div>
                        <div class="control">
                            <button type="submit" class="button save-button" translate>{{'form.save'}}</button>
                        </div>
                    </div>
                </form>
                </div>
                <div class="content-header">
                    <div *ngIf="userEditedSection" >
                        {{'optionEditor.optionChosen' | translate}}: <strong>{{userEditedSection.optionId && getOptionFromUserDocument(userEditedSection)? getOptionFromUserDocument(userEditedSection).optionName : ''}}</strong>
                    </div>
                    <app-autocomplete-input
                        class="options-search-bar ml-3"
                        [options]="options"
                        [placeholder]="'optionEditor.changeOptionPlaceholder'"
                        [notFoundPlaceholder]="'form.noElementsInSearch'"
                        [selector]="'optionName'"
                        [isFormControl]="false"
                        (optionClicked)="selectOption($event)"
                    ></app-autocomplete-input>
                </div>
                    <tiptap-editor *ngIf="content"
                        [initialContent]="content" 
                        [isSaving] ="isSaving"
                        (saveContent)="saveContent($event)" 
                    ></tiptap-editor>
            </div>
        </div>

</div>