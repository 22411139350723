import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserFacade } from '@services/user/user.facade';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private userFacade: UserFacade, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.authService.isAuthenticated()) {
      return this.userFacade.currentUser.pipe(
        switchMap((user) => {
          if (user) {
            if(!user.email_verified) {
              return this.router.navigate(['/verify-email']);
            }
            return of(true);
          }
          let userFromToken = this.authService.getUserFromToken()
          if (userFromToken) {
            this.userFacade.setCurrentUser(this.authService.getUserFromToken());
            if(!userFromToken.email_verified) {
              return this.router.navigate(['/verify-email']);
            }
            return of(true);
          }
          return this.router.navigate(['/login']);
        })
      );
    }
    this.router.navigate(['/login']);
    return of(false);
  }
}
