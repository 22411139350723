import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserFacade } from '@services/user/user.facade';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('navBurger') navBurger: ElementRef;
  @ViewChild('navMenu') navMenu: ElementRef;

  username: string;
  showLanguages:boolean = false;

  constructor(private readonly userFacade: UserFacade, private translate: TranslateService) { }

  ngOnInit(): void {
    this.userFacade.currentUser.subscribe((user) => {
      if (user) {
        this.username = user.username;
      }
    })
  }

  toggleNavbar() {
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }
  
  logout() {
    this.userFacade.logoutUser();
  }
  
  setShowLanguages() {
    this.showLanguages = !this.showLanguages;
  }

  selectLanguage(lang: string ) {
    this.showLanguages = false;
    localStorage.setItem('lang', lang);
    this.translate.use(lang)
  }
}
