<div class="layout">
  <div class="left-color gradient-background">
  <h1 class="thin-title white-color" translate>{{'signup.createAccount'}}</h1>

      <div class='diagonal-div'></div>
  </div>

  <div class="left-white">
      <h1 class="thin-title" translate>{{'signup.signUp'}}</h1>    
      <h2 class="thin-subtitle" translate>{{'login.signupSubtitle'}}</h2>

      <div class="content">
        <div *ngIf="loginError" class='error-box' translate>{{'form.error'}}</div>
        <form [formGroup]="form" (ngSubmit)="signUp()">
          <div class="field">
              <div class="control">
              <input class="input" type="email" placeholder="{{'form.email' |translate}}" formControlName="email">
              </div>
          </div>
          <div *ngIf="form.controls.email?.invalid && (form.controls.email?.dirty || form.controls.email?.touched)" class="message is-danger">
              <div class="message-body" translate><strong>{{'form.error' | translate}}:</strong> {{'form.wrongEmail'}}</div>
          </div>
          <div class="field">
           <div class="control">
                  <input class="input" type="password" placeholder="{{'form.password' |translate}}" formControlName="password">
              </div>
          </div>
          <div class="field">
           <div class="control">
                  <input class="input" type="password" placeholder="{{'form.repetirPassword' |translate}}" formControlName="passwordRepeat">
              </div>
          </div>
          <div *ngIf="form.controls.passwordRepeat?.invalid &&
        (form.controls.passwordRepeat?.dirty || form.controls.passwordRepeat?.touched) &&
          (form.controls.passwordRepeat.errors.mustMatch || form.controls.passwordRepeat.errors.minlength)" class="message is-danger">
          <div class="message-body" translate><strong>{{'form.error' | translate}}: {{'signup.passwordInvalid' | translate}}</strong> </div>
          </div>

          <button class="button mt-3 is-block is-success is-fullwidth"
              type="submit"
              [disabled]="form.invalid"
              translate
              >
              {{'signup.signUp'}}
          </button>
        </form>

      </div>

      <p class="text" translate>{{'signup.loginReminder'}}
          <a href="/login"> <strong class='text text-highlight'  translate>{{'login.logIn'}}</strong></a>
      </p>
  </div>
  
</div>