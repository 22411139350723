import { SetCurrentUser, UserActions, UserActionsType } from '@services/user/user.actions';

import { UserModel } from '@services/user/user.model';
import { StateActionsType } from 'src/app/store/state.actions';

export interface UserState {
  currentUser: UserModel;
}

export const userInitialState: UserState = {
  currentUser: null,
};

export function userReducer(state = userInitialState, action: UserActions) {
  switch (action.type) {
    case UserActionsType.SetCurrentUserType:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionsType.RefreshTokenSuccessType:
      console.log("userReducer RefreshTokenSuccessType", action.token)

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          token: {
            ...state.currentUser.token,
            access_token: action.token
          }
        }
      };
    case StateActionsType.ResetStoreType:
      return {
        currentUser: null
      };
    default:
      return state;
  }
}

export const getCurrentUser = (state: UserState): UserModel => {
  if(state.currentUser) {return state.currentUser}
  //else {
  //  // recovering from LocalStorage means the page was refreshed
  //  let curUser = JSON.parse(localStorage.getItem('currentUser')) as UserModel ;
  //  state = userReducer(state, new SetCurrentUser(curUser))
  //  if (curUser) {
  //    return state.currentUser;
  //  }
  //}
};
