import { NgModule } from '@angular/core';

import { FadeScrollElementDirective } from './fade-scroll-element/fade-scroll-element.directive';
import { Modal } from './modal/modal.component';
import { OutsideClickDirective } from './outside-click/outside-click.directive';
import { ViewContainerDirective } from './view-container-element/view-container-element.directive';

@NgModule({
  declarations: [FadeScrollElementDirective, ViewContainerDirective, OutsideClickDirective, Modal],
  exports: [FadeScrollElementDirective, ViewContainerDirective, OutsideClickDirective, Modal],
})
export class DirectivesModule {}
