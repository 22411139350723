<div #modal class="modal is-clipped">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <h1 class="modal-card-title"> {{ title }} </h1>
        <button class="delete" aria-label="close" (click)="hideEditElementModal()"></button>
      </header>
      <section class="modal-card-body" >
        <!-- Content ... -->
        <ng-content></ng-content>
                 
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>