import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ProjectDocuments, Section, Option, UserDocument, Project, Document, SectionTree } from './templates.model';

export enum TemplatesActionsType {
  
  LoadProjectsAndDocumentsType = '[Templates] Fetch the projects and the available documents in each project',
  LoadProjectsAndDocumentsSuccessType = '[Templates] Projects and the available documents fetched',
  LoadProjectsAndDocumentsErrorType = '[Templates] Error while fetching projects and the available documents',

  LoadSectionsInDocumentType = '[Templates] Fetch the sections that conform the document',
  LoadSectionsInDocumentSuccessType = '[Templates] Sections in document fetched',
  LoadSectionsInDocumentErrorType = '[Templates] Error while fetching sections in document',

  LoadTreeType = '[Templates] Fetch the tree available for a specific document',
  LoadTreeSuccessType = '[Templates] Tree specific document fetched',
  LoadTreeErrorType = '[Templates] Errro while fetching the tree available for a specific document',

  LoadOptionsInSectionType = '[Templates] Fetch the options available for a specific section',
  LoadOptionsInSectionSuccessType = '[Templates] Options in section fetched',
  LoadOptionsInSectionErrorType = '[Templates] Error while fetching options in section',

  LoadRecentDocumentsType = '[Templates] Fetch the recent documents',
  LoadRecentDocumentsSuccessType = '[Templates] Recent documents fetched',
  LoadRecentDocumentsErrorType = '[Templates] Error while fetching recent documents',

  LoadRecentDocumentType = '[Templates] Fetch specific recent documents',
  LoadRecentDocumentSuccessType = '[Templates] Specific documents fetched',
  LoadRecentDocumentErrorType = '[Templates] Error while fetching specific recent documents',

  // Create
  CreateProjectType = '[Templates] Create project',
  CreateProjectSuccessType = '[Templates] Create project succeed',
  CreateProjectErrorType = '[Templates] Error while creating project',

  CreateDocumentType = '[Templates] Create document',
  CreateDocumentSuccessType = '[Templates] Create document succeed',
  CreateDocumentErrorType = '[Templates] Error while creating document',

  CreateSectionType = '[Templates] Create Section',
  CreateSectionSuccessType = '[Templates] Create Section succeed',
  CreateSectionErrorType = '[Templates] Error while creating Section',

  CreateOptionType = '[Templates] Create Option',
  CreateOptionSuccessType = '[Templates] Create Option succeed',
  CreateOptionErrorType = '[Templates] Error while creating Option',

  // Modifies
  PatchProjectType = '[Templates] Patch Project',
  PatchProjectSuccessType = '[Templates] Patch Project succeed',
  PatchProjectErrorType = '[Templates] Error while patching Project',

  PatchDocumentType = '[Templates] Patch Document',
  PatchDocumentSuccessType = '[Templates] Patch Document succeed',
  PatchDocumentErrorType = '[Templates] Error while patching Document',

  PatchSectionType = '[Templates] Patch Section',
  PatchSectionSuccessType = '[Templates] Patch Section succeed',
  PatchSectionErrorType = '[Templates] Error while patching Section',

  PatchOptionType = '[Templates] Patch option',
  PatchOptionSuccessType = '[Templates] Patch option succeed',
  PatchOptionErrorType = '[Templates] Error while patching option',

  // Delete
  DeleteProjectType = '[Templates] Delete project',
  DeleteProjectSuccessType = '[Templates] Delete project succeed',
  DeleteProjectErrorType = '[Templates] Error while deleting project',

  DeleteDocumentType = '[Templates] Delete Document',
  DeleteDocumentSuccessType = '[Templates] Delete Document succeed',
  DeleteDocumentErrorType = '[Templates] Error while deleting Document',

  DeleteSectionType = '[Templates] Delete Section',
  DeleteSectionSuccessType = '[Templates] Delete Section succeed',
  DeleteSectionErrorType = '[Templates] Error while deleting Section',

  DeleteOptionType = '[Templates] Delete Option',
  DeleteOptionSuccessType = '[Templates] Delete Option succeed',
  DeleteOptionErrorType = '[Templates] Error while deleting Option',

  CreateUserDocumentType = '[Templates] Create User Document',
  CreateUserDocumentSuccessType = '[Templates] Creation User Document succeed',
  CreateUserDocumentErrorType = '[Templates] User Document failed',

  ModifyUserDocumentType = '[Templates] Modify User Document',
  ModifyUserDocumentSuccessType = '[Templates] Modification User Document succeed',
  ModifyUserDocumentErrorType = '[Templates] Modification User Document failed',

  DeleteUserDocumentType = '[Templates] Delete User Document',
  DeleteUserDocumentSuccessType = '[Templates] Deletion User Document succeed',
  DeleteUserDocumentErrorType = '[Templates] Deletion User Document failed',

  Flush = 'Flush State'
}

export class Flush implements Action {
  readonly type = TemplatesActionsType.Flush;
}

export class LoadProjectsAndDocuments implements Action {
  readonly type = TemplatesActionsType.LoadProjectsAndDocumentsType;
}

export class LoadProjectsAndDocumentsFetched implements Action {
  readonly type = TemplatesActionsType.LoadProjectsAndDocumentsSuccessType;
  constructor(public projectAndDocumentsList: Array<ProjectDocuments>){}
}

export class LoadProjectsAndDocumentsError implements Action {
  readonly type = TemplatesActionsType.LoadSectionsInDocumentErrorType;
}

export class LoadTreeDocument implements Action {
  readonly type = TemplatesActionsType.LoadTreeType;
  constructor(public projectId:number, public documentId:number) {}
}

export class LoadTreeDocumentFetched implements Action {
  readonly type = TemplatesActionsType.LoadTreeSuccessType;
  constructor(public projectId:number, public documentId:number, public sections: Array<SectionTree>) {}
}

export class LoadTreeDocumentError implements Action {
  readonly type = TemplatesActionsType.LoadTreeErrorType;
}

export class LoadSectionsInDocument implements Action {
  readonly type = TemplatesActionsType.LoadSectionsInDocumentType;
  constructor(public projectId:number, public documentId:number) {}
}

export class LoadSectionsInDocumentFetched implements Action {
  readonly type = TemplatesActionsType.LoadSectionsInDocumentSuccessType;
  constructor(public projectId:number, public documentId:number, public sections: Array<Section>) {}
}

export class LoadSectionsInDocumentError implements Action {
  readonly type = TemplatesActionsType.LoadSectionsInDocumentErrorType;
}

export class LoadOptionsInSection implements Action {
  readonly type = TemplatesActionsType.LoadOptionsInSectionType;
  constructor(public documentId:number, public sectionId: number) {}
}

export class LoadOptionsInSectionFetched implements Action {
  readonly type = TemplatesActionsType.LoadOptionsInSectionSuccessType;
  constructor(public sectionId: number, public options: Array<Option>) {}
}

export class LoadOptionsInSectionError implements Action {
  readonly type = TemplatesActionsType.LoadOptionsInSectionErrorType;
}

export class LoadRecentDocuments implements Action {
  readonly type = TemplatesActionsType.LoadRecentDocumentsType;
  constructor(public userId:string) {}
}

export class LoadRecentDocumentsFetched implements Action {
  readonly type = TemplatesActionsType.LoadRecentDocumentsSuccessType;
  constructor(public recents: Array<UserDocument>) {}
}

export class LoadRecentDocumentsError implements Action {
  readonly type = TemplatesActionsType.LoadRecentDocumentsErrorType;
}


export class LoadRecentDocument implements Action {
  readonly type = TemplatesActionsType.LoadRecentDocumentType;
  constructor(public userId:string, public userDocumentId:number) {}
}

export class LoadRecentDocumentFetched implements Action {
  readonly type = TemplatesActionsType.LoadRecentDocumentSuccessType;
  constructor(public document: UserDocument) {}
}

export class LoadRecentDocumentError implements Action {
  readonly type = TemplatesActionsType.LoadRecentDocumentErrorType;
}

// Create

export class CreateProject implements Action {
  readonly type = TemplatesActionsType.CreateProjectType;
  constructor(public request: Project) {}
}

export class CreateProjectFetched implements Action {
  readonly type = TemplatesActionsType.CreateProjectSuccessType;
  constructor(public project: Project) {}
}

export class CreateProjectError implements Action {
  readonly type = TemplatesActionsType.CreateProjectErrorType;
}

export class CreateDocument implements Action {
  readonly type = TemplatesActionsType.CreateDocumentType;
  constructor(public projectId: number, public request: Document) {}
}

export class CreateDocumentFetched implements Action {
  readonly type = TemplatesActionsType.CreateDocumentSuccessType;
  constructor(public document: Document, public projectId: number) {}
}

export class CreateDocumentError implements Action {
  readonly type = TemplatesActionsType.CreateDocumentErrorType;
}

export class CreateSection implements Action {
  readonly type = TemplatesActionsType.CreateSectionType;
  constructor(public projectId: number, 
              public documentId: number,
              public request: Section) {}
}

export class CreateSectionFetched implements Action {
  readonly type = TemplatesActionsType.CreateSectionSuccessType;
  constructor(public section: Section, public documentId: number) {}
}

export class CreateSectionError implements Action {
  readonly type = TemplatesActionsType.CreateSectionErrorType;
}

export class CreateOption implements Action {
  readonly type = TemplatesActionsType.CreateOptionType;
  constructor(public projectId: number, 
              public documentId: number, 
              public sectionId: number, 
              public request: Option) {}
}

export class CreateOptionFetched implements Action {
  readonly type = TemplatesActionsType.CreateOptionSuccessType;
  constructor(public option: Option, public sectionId: number) {}
}

export class CreateOptionError implements Action {
  readonly type = TemplatesActionsType.CreateOptionErrorType;
}

// Modifies

export class PatchProject implements Action {
  readonly type = TemplatesActionsType.PatchProjectType;
  constructor(public patch: Project) {}
}

export class PatchProjectFetched implements Action {
  readonly type = TemplatesActionsType.PatchProjectSuccessType;
  constructor(public project: Project) {}
}

export class PatchProjectError implements Action {
  readonly type = TemplatesActionsType.PatchProjectErrorType;
}

export class PatchDocument implements Action {
  readonly type = TemplatesActionsType.PatchDocumentType;
  constructor(public projectId: number, public request: Document) {}
}

export class PatchDocumentFetched implements Action {
  readonly type = TemplatesActionsType.PatchDocumentSuccessType;
  constructor(public document: Document) {}
}

export class PatchDocumentError implements Action {
  readonly type = TemplatesActionsType.PatchDocumentErrorType;
}

export class PatchSection implements Action {
  readonly type = TemplatesActionsType.PatchSectionType;
  constructor(public projectId: number, 
              public documentId: number,
              public request: Section) {}
}

export class PatchSectionFetched implements Action {
  readonly type = TemplatesActionsType.PatchSectionSuccessType;
  constructor(public section: Section) {}
}

export class PatchSectionError implements Action {
  readonly type = TemplatesActionsType.PatchSectionErrorType;
}

export class PatchOption implements Action {
  readonly type = TemplatesActionsType.PatchOptionType;
  constructor(public projectId: number, 
              public documentId: number, 
              public sectionId: number, 
              public patch: Option) {}
}

export class PatchOptionFetched implements Action {
  readonly type = TemplatesActionsType.PatchOptionSuccessType;
  constructor(public option: Option) {}
}

export class PatchOptionError implements Action {
  readonly type = TemplatesActionsType.PatchOptionErrorType;
}

// Deletes

export class DeleteProject implements Action {
  readonly type = TemplatesActionsType.DeleteProjectType;
  constructor(public projectId: number) {}
}

export class DeleteProjectDone implements Action {
  readonly type = TemplatesActionsType.DeleteProjectSuccessType;
  constructor(public projectId: number){}
}

export class DeleteProjectError implements Action {
  readonly type = TemplatesActionsType.PatchProjectErrorType;
}

export class DeleteDocument implements Action {
  readonly type = TemplatesActionsType.DeleteDocumentType;
  constructor(public projectId: number, 
              public documentId: number) {}
}

export class DeleteDocumentDone implements Action {
  readonly type = TemplatesActionsType.DeleteDocumentSuccessType;
  constructor(public projectId: number, public documentId: number){}
}

export class DeleteDocumentError implements Action {
  readonly type = TemplatesActionsType.PatchDocumentErrorType;
}

export class DeleteSection implements Action {
  readonly type = TemplatesActionsType.DeleteSectionType;
  constructor(public projectId: number, 
              public documentId: number, 
              public sectionId: number) {}
}

export class DeleteSectionDone implements Action {
  readonly type = TemplatesActionsType.DeleteSectionSuccessType;
  constructor(public sectionId: number){}
}

export class DeleteSectionError implements Action {
  readonly type = TemplatesActionsType.PatchSectionErrorType;
}

export class DeleteOption implements Action {
  readonly type = TemplatesActionsType.DeleteOptionType;
  constructor(public projectId: number, 
              public documentId: number, 
              public sectionId: number, 
              public optionId: number) {}
}

export class DeleteOptionDone implements Action {
  readonly type = TemplatesActionsType.DeleteOptionSuccessType;
  constructor(public sectionId: number, public optionId: number){}
}

export class DeleteOptionError implements Action {
  readonly type = TemplatesActionsType.DeleteOptionErrorType;
}

export class CreateUserDocument implements Action {
  readonly type = TemplatesActionsType.CreateUserDocumentType;
  constructor(public userId: string, 
              public userDocument: UserDocument) {}
}

export class CreateUserDocumentSuccess implements Action {
  readonly type = TemplatesActionsType.CreateUserDocumentSuccessType;
  constructor(public userDocument: UserDocument){}
}

export class CreateUserDocumentError implements Action {
  readonly type = TemplatesActionsType.CreateUserDocumentErrorType;
}

export class ModifyUserDocument implements Action {
  readonly type = TemplatesActionsType.ModifyUserDocumentType;
  constructor(public userId: string, 
              public userDocument: UserDocument) {}
}

export class ModifyUserDocumentSuccess implements Action {
  readonly type = TemplatesActionsType.ModifyUserDocumentSuccessType;
  constructor(public userDocument: UserDocument){}
}

export class ModifyUserDocumentError implements Action {
  readonly type = TemplatesActionsType.ModifyUserDocumentErrorType;
}

export class DeleteUserDocument implements Action {
  readonly type = TemplatesActionsType.DeleteUserDocumentType;
  constructor(public userId: string, 
              public userDocumentId: number) {}
}

export class DeleteUserDocumentSuccess implements Action {
  readonly type = TemplatesActionsType.DeleteUserDocumentSuccessType;
  constructor(public userDocumentId: number) {}
}

export class DeleteUserDocumentError implements Action {
  readonly type = TemplatesActionsType.DeleteUserDocumentErrorType;
}

export type TemplatesActions = 
Flush |
LoadProjectsAndDocuments | 
LoadProjectsAndDocumentsFetched | 
LoadProjectsAndDocumentsError | 
LoadTreeDocument |
LoadTreeDocumentFetched |
LoadTreeDocumentError |
LoadSectionsInDocument |
LoadSectionsInDocumentFetched |
LoadSectionsInDocumentError |
LoadOptionsInSection |
LoadOptionsInSectionFetched |
LoadOptionsInSectionError |
LoadRecentDocuments |
LoadRecentDocumentsFetched |
LoadRecentDocumentsError|
LoadRecentDocument |
LoadRecentDocumentFetched |
LoadRecentDocumentError |

// creates
CreateProject |
CreateProjectFetched |
CreateProjectError |
CreateDocument |
CreateDocumentFetched |
CreateDocumentError |
CreateSection |
CreateSectionFetched |
CreateSectionError |
CreateOption |
CreateOptionFetched |
CreateOptionError |

// Modifies
PatchProject |
PatchProjectFetched |
PatchProjectError |
PatchDocument |
PatchDocumentFetched |
PatchDocumentError|
PatchSection |
PatchSectionFetched |
PatchSectionError |
PatchOption |
PatchOptionFetched |
PatchOptionError |

// Deletes
DeleteProject |
DeleteProjectDone |
DeleteProjectError |
DeleteDocument |
DeleteDocumentDone |
DeleteDocumentError |
DeleteSection |
DeleteSectionDone |
DeleteSectionError |
DeleteOption |
DeleteOptionDone |
DeleteOptionError |

CreateUserDocument |
CreateUserDocumentSuccess |
CreateUserDocumentError |
ModifyUserDocument |
ModifyUserDocumentSuccess |
ModifyUserDocumentError |
DeleteUserDocument |
DeleteUserDocumentSuccess |
DeleteUserDocumentError 
;
