import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserFacade } from '@services/user/user.facade';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  form: FormGroup;
  loginError: boolean;
  
  constructor(
    private formBuilder: FormBuilder, 
    private userfacade: UserFacade, 
    private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  signUp() {
     this.userfacade.signUp(this.form.value.email,this.form.value.password,'es').subscribe(
       (res) => {this.router.navigate(['/verify-email']);},
       (error)=> {
        this.loginError = true;
       }
     );
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      passwordRepeat: new FormControl('', [Validators.required, Validators.minLength(8)]),
    },
      {
        validator: MustMatch('password', 'passwordRepeat'),
      });
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
