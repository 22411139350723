import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { ForgetMe, ForgotPassword, LoginUser, LogoutCurrentUser, RefreshToken, RefreshTokenInterval, SetCurrentUser, SignUpUser, UserActionsType } from '@services/user/user.actions';
import { UserState } from '@services/user/user.reducer';
import { StateFacade } from '../../store/state.facade';

import { UserModel } from '@services/user/user.model';

import { getCurrentUser } from '../../store/store';
import { AuthService } from '@services/auth/auth.service';


@Injectable({
  providedIn: 'root',
})
export class UserFacade extends StateFacade {
  private refreshInterval;

  get currentUser(): Observable<UserModel> {
    return this.store.pipe(select(getCurrentUser));
  }

  constructor(public actions: Actions, private readonly userStore: Store<UserState>, private readonly authService: AuthService) {
    super(actions, userStore);
  }


  loginUser(username: string, password: string): Observable<UserModel> {
    return this.dispatchActionAndGetResult(
      new LoginUser(username, password),
      UserActionsType.SetCurrentUserType,
      UserActionsType.UserCommonErrorType
    )
  }

  logoutUser(): void {
    clearInterval(this.refreshInterval);
    this.userStore.dispatch(new LogoutCurrentUser());
  }

  forgotPasword(email: string): void {
    this.userStore.dispatch(new ForgotPassword(email));
  }

  forgetMe(email: string): void {
    this.userStore.dispatch(new ForgetMe(email));
  }

  signUp(email: string, password: string, locale: string): Observable<void> {
    return this.dispatchActionAndGetResult(
      new SignUpUser(email, password, locale),
      UserActionsType.SignUpUserSuccessType,
      UserActionsType.UserCommonErrorType
    );
  }
  
  setCurrentUser(user: UserModel) {
    this.store.dispatch(new SetCurrentUser(user));
  }
}
