import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { UserModel } from '@services/user/user.model';
import { ResetStore } from 'src/app/store/state.actions';

export enum UserActionsType {
  LoginUserType = '[User] Log in user',
  SetCurrentUserType = '[User] Set current user',
  LogoutUserType = '[User] Logout current user',
  UserCommonErrorType = '[User] General error',

  SignUpUserType = '[User] Sign up user',
  SignUpUserSuccessType = '[User] User signed up',

  ForgotPasswordType = '[User] Asked to recover the password',
  ForgetMeType = '[User] Asked to forget the account',

  RefreshTokenType = '[User] reresh token',
  RefreshTokenSuccessType = '[User] reresh token succeed',
  RefreshTokenIntervalType = '[User] Start Interval'
}

export class LoginUser implements Action {
  readonly type = UserActionsType.LoginUserType;
  constructor(public username: string, public password: string) {}
}

export class SetCurrentUser implements Action {
  readonly type = UserActionsType.SetCurrentUserType;
  constructor(public payload: UserModel) {}
}

export class LogoutCurrentUser implements Action {
  readonly type = UserActionsType.LogoutUserType;
}

export class ForgotPassword implements Action {
  readonly type = UserActionsType.ForgotPasswordType;
  constructor(public email: string) {}
}

export class ForgetMe implements Action {
  readonly type = UserActionsType.ForgetMeType;
  constructor(public email: string) {}
}

export class SignUpUser implements Action {
  readonly type = UserActionsType.SignUpUserType;
  constructor(public email: string, public password: string, public locale: string) {}
}

export class SignUpUserSuccess implements Action {
  readonly type = UserActionsType.SignUpUserSuccessType;
  constructor(public payload: UserModel) {}
}

export class UserCommonError implements Action {
  readonly type = UserActionsType.UserCommonErrorType;
  constructor(public payload: HttpErrorResponse) {}
}

export class RefreshToken implements Action {
  readonly type = UserActionsType.RefreshTokenType;
  constructor() {}
}

export class RefreshTokenSuccess implements Action {
  readonly type = UserActionsType.RefreshTokenSuccessType;
  constructor(public token) {}
}

export class RefreshTokenInterval implements Action {
  readonly type = UserActionsType.RefreshTokenIntervalType;
}

export type UserActions = UserCommonError | LoginUser | SetCurrentUser 
| ResetStore 
| RefreshToken | RefreshTokenSuccess | RefreshTokenInterval;
