import { reportInvalidActions } from '@ngrx/effects/src/effect_notification';
import { ProjectDocuments, Section, Option, UserDocument, SectionTree } from '@services/templates/templates.model';
import { TemplatesActions, TemplatesActionsType } from './templates.actions';

export interface TemplateState {
  projectsDocuments: Array<ProjectDocuments>

  selectedProjectId: number;
  selectedDocumentId: number;
  treeDocument: Array<SectionTree>;
  sectionsInDocument: Array<Section>;
  selectedSectionId: number;
  optionsInSection: Array<Option>;

  recentDocuments: Array<UserDocument>;
  userDocument: UserDocument;
}

export const templateInitialState: TemplateState = {
projectsDocuments: null,
selectedProjectId: null,
selectedDocumentId:null,
treeDocument: null,
sectionsInDocument:null,
selectedSectionId: null,
optionsInSection: null,
recentDocuments: null,
userDocument: null,
};

export function templatesReducer(state = templateInitialState, action: TemplatesActions) {
  switch (action.type) {
    case TemplatesActionsType.Flush:
      return {... templateInitialState};
    case TemplatesActionsType.LoadProjectsAndDocumentsSuccessType:
      return {
        ...state,
        projectsDocuments: action.projectAndDocumentsList,
      };
    
      case TemplatesActionsType.LoadTreeSuccessType:
      return {
        ...state,
        selectedProjectId: action.documentId,
        selectedDocumentId: action.documentId,
        treeDocument: sortSectionByOrder(action.sections),
        sectionsInDocument: action.sections
      };

      case TemplatesActionsType.LoadSectionsInDocumentSuccessType:
      return {
        ...state,
        selectedProjectId: action.documentId,
        selectedDocumentId: action.documentId,
        sectionsInDocument: action.sections
      };

    case TemplatesActionsType.LoadOptionsInSectionSuccessType:
      return {
        ...state,
        selectedSectionId: action.sectionId,
        optionsInSection: action.options
      };

    case TemplatesActionsType.LoadRecentDocumentsSuccessType:
      return {
        ...state,
        recentDocuments: action.recents.map(rd => { let dt = new Date(rd.updatedAt); return {...rd, updatedAt: dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString()  }}),
      };

    case TemplatesActionsType.LoadRecentDocumentSuccessType:
      return {
        ...state,
        userDocument: action.document,
        selectedDocumentId: action.document.documentId,
      }; 

    case TemplatesActionsType.CreateUserDocumentSuccessType:
      let rdC = state.recentDocuments ? [...state.recentDocuments, action.userDocument] :[action.userDocument]
      return {
        ...state,
        userDocument: action.userDocument,
        recentDocuments: rdC.map(rd => { let dt = new Date(rd.updatedAt); return {...rd, updatedAt: dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString()  }}),
      };
    
    case TemplatesActionsType.ModifyUserDocumentSuccessType:
      let rd = state.recentDocuments ? state.recentDocuments.filter(rd=> rd.documentId !== action.userDocument.documentId) : [];
      return {
        ...state,
        userDocument: action.userDocument,
        recentDocuments: [...rd, action.userDocument].map(rd => { let dt = new Date(rd.updatedAt); return {...rd, updatedAt: dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString()  }})
      };

    case TemplatesActionsType.DeleteUserDocumentSuccessType:
      return {
        ...state,
        userDocument: null,
        recentDocuments: [...state.recentDocuments.filter(rd=> rd.userDocumentId !== action.userDocumentId)].map(rd => { let dt = new Date(rd.updatedAt); return {...rd, updatedAt: dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString()  }})
      };
    // Create

    case TemplatesActionsType.CreateProjectSuccessType:
      return {
        ...state,
        selectedProjectId: action.project.projectId,
        projectsDocuments: [...state.projectsDocuments, {project: action.project, documents: []}]
      }; 
 
    case TemplatesActionsType.CreateDocumentSuccessType:
    
      let affectedProjectDocuments = {...state.projectsDocuments.find(o=> o.project.projectId === action.projectId)};
      let otherProjectsAndDocuments = state.projectsDocuments.filter(o=> o.project.projectId !== action.projectId);
      if(affectedProjectDocuments) {
        let newProjectsAndDocuments = {
          ...affectedProjectDocuments,
          documents : [...affectedProjectDocuments.documents, action.document]
        }
        return {
          ...state,
          selectedProjectId: action.projectId,
          projectsDocuments: [...otherProjectsAndDocuments, newProjectsAndDocuments],
          selectedDocumentId: action.document.documentId
        };  
      } else {
        return {...state};
      }
         
    case TemplatesActionsType.CreateSectionSuccessType:
      let newListSectionOfTree = [...state.treeDocument, {...action.section, options: []}]
      return {
        ...state,
        selectedDocument: action.documentId,
        sectionsInDocument: [...state.sectionsInDocument, action.section],
        treeDocument: sortSectionByOrder(newListSectionOfTree),
      }; 

    case TemplatesActionsType.CreateOptionSuccessType:
      let editedSection = state.treeDocument.find(s => s.sectionId == action.sectionId)
      return {
        ...state,
        selectedSection: action.sectionId,
        optionsInSection: [...state.optionsInSection, action.option],
        treeDocument: sortSectionByOrder([...state.treeDocument.filter(s => s.sectionId != action.sectionId), 
          {...editedSection, options:[... editedSection.options, {...action.option}] }]),
      };  

    // Modifies
    case TemplatesActionsType.PatchProjectSuccessType:
      let structureProject = state.projectsDocuments.find(o => o.project.projectId === action.project.projectId);
      if (structureProject) {
        structureProject = {...structureProject, project: action.project}
        let pd = state.projectsDocuments.filter(pd=> pd.project.projectId !== action.project.projectId);
        return {
          ...state,
          projectsDocuments: [...pd, {...structureProject}]
        }; 
      }
      return state; 
      
      case TemplatesActionsType.PatchDocumentSuccessType:
        let structure = {...state.projectsDocuments.find(o => 
          o.documents.find(d => d.documentId === action.document.documentId) != undefined)};
        if (structure) {
          let nds = [...structure.documents.filter(d => d.documentId !== action.document.documentId), action.document];
          let pd = state.projectsDocuments.filter(pd=> pd.project.projectId !== structure.project.projectId);
          return {
            ...state,
            projectsDocuments: [...pd, {...structure, documents: nds}]
          }; 
        }
        return state; 

    case TemplatesActionsType.PatchSectionSuccessType:
      let sections = state.sectionsInDocument.filter(o => o.sectionId != action.section.sectionId);
      let sectionsInTree = state.treeDocument.filter(o => o.sectionId != action.section.sectionId);
      let affectedSectionsInTree = state.treeDocument.find(o => o.sectionId == action.section.sectionId);
      return {
        ...state,
        sectionsInDocument: [...sections, action.section],
        treeDocument: sortSectionByOrder([...sectionsInTree, {...action.section, options:[...affectedSectionsInTree.options]}])
      }; 
      
    case TemplatesActionsType.PatchOptionSuccessType:
      let options = state.optionsInSection.filter(o => o.optionId != action.option.optionId);
      let sectionAfected = state.treeDocument.find(s => s.sectionId == state.selectedSectionId);
      let otherSections = state.treeDocument.filter(s => s.sectionId != state.selectedSectionId);
      let otherOptionsInSectionAffected = sectionAfected.options.filter(o => o.optionId != action.option.optionId);
      return {
        ...state,
        optionsInSection: [...options, action.option],
        treeDocument : sortSectionByOrder([...otherSections,  {...sectionAfected, options: [...otherOptionsInSectionAffected, action.option]}])
      }; 

    // Delete
    case TemplatesActionsType.DeleteProjectSuccessType:
      let projects = state.projectsDocuments.filter(o => o.project.projectId != action.projectId);
      return {
        ...state,
        projectsDocuments: projects,
      }; 

    case TemplatesActionsType.DeleteDocumentSuccessType:
      let affectedProject = state.projectsDocuments.find(o => o.project.projectId === action.projectId);
      let remainingProjects = state.projectsDocuments.filter(o => o.project.projectId != action.projectId);
      if(affectedProject) {
        let pd = {
          ...affectedProject,
          documents: affectedProject.documents.filter(d => d.documentId !== action.documentId)
        }
        return {
          ...state,
          projectsDocuments: [...remainingProjects, pd]
        };   
        
      }
      return {...state};   
      
    case TemplatesActionsType.DeleteSectionSuccessType:
      let remainingSections = state.sectionsInDocument.filter(o => o.sectionId !== action.sectionId);
      return {
        ...state,
        sectionsInDocument: remainingSections,
        treeDocument : sortSectionByOrder([...state.treeDocument.filter(s=> s.sectionId != action.sectionId)])
      }; 

    case TemplatesActionsType.DeleteOptionSuccessType:
      let remainingOptions = state.optionsInSection.filter(o => o.optionId !== action.optionId);
      let remainingOptionsInSelectedTreeSection = state.treeDocument.filter(s=>s.sectionId !== action.sectionId)
      let sectionAfectedByDeletion = state.treeDocument.find(s => s.sectionId == state.selectedSectionId);

      return {
        ...state,
        optionsInSection: remainingOptions,
        treeDocument : sortSectionByOrder([
          ...remainingOptionsInSelectedTreeSection, 
          {...sectionAfectedByDeletion, options: sectionAfectedByDeletion.options.filter(o => o.optionId != action.optionId)}
        ])
      };  
    default:
      return state;
  }
}

  const sortSectionByOrder = (arr: SectionTree []): SectionTree[] => {
    let orders: string[] =  arr.map( a => a.sectionOrder.split('.').map( n => +n+100000 ).join('.') ).sort()
    .map( a => a.split('.').map( n => +n-100000 ).join('.') )

    let sorted: SectionTree[] = []
    for (const order of orders) {
      sorted.push(arr.find( a => a.sectionOrder == order))
    }
    return sorted;
    //return arr;
  };

export const getProjectsAndDocuments = (state: TemplateState): Array<ProjectDocuments> => state.projectsDocuments;
export const treeDocument = (state: TemplateState): Array<SectionTree> => state.treeDocument;
export const sectionsInDocument = (state: TemplateState): Array<Section> => state.sectionsInDocument;
export const optionsInSection = (state: TemplateState): Array<Option> => state.optionsInSection;
export const userDocument = (state: TemplateState): UserDocument => state.userDocument;

export const recentDocuments = (state: TemplateState): Array<UserDocument> => state.recentDocuments;
