import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[appOutsideClick]',
})
export class OutsideClickDirective {
  /**
   * Emits when the user clicks outside of the target element
   */
  @Output() outsideClick: EventEmitter<void> = new EventEmitter();

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('document:click', ['$event']) onClick(clickEvent: MouseEvent) {
    if (clickEvent.target && !this.elementRef.nativeElement.contains(clickEvent.target)) {
      this.outsideClick.emit();
    }
  }
}
