import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TemplatesFacade } from '@services/templates/templates.facade';
import { Project, Document, Option, ProjectDocuments, SectionTree, Section } from '@services/templates/templates.model';

@Component({
    selector: 'template-editor',
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit {
    projectsAndDocs: Array<ProjectDocuments> = [];
    tree: Array<SectionTree> = [];

    selectedProject: Project;
    selectedDocument: Document;
    selectedSection: SectionTree;
    selectedOption: Option;
    isSaving: false;

    activeTab: 'area' | 'document' | 'section' | 'option' = 'area';

    formCreateResource: FormGroup;
    formDeleteResource: FormGroup;
    formEditResource: FormGroup;

    isModalForCreateOpen: boolean = false;
    isModalForDeletionOpen: boolean = false;
    isModalForEditionOpen: boolean = false;

    constructor(
        private templateFacade: TemplatesFacade,
        private readonly changeDetection: ChangeDetectorRef,
        private translate: TranslateService) { }

    ngOnInit(): void {
        this.createForm();

        this.templateFacade.projectsAnDocuments();

        this.templateFacade.getProjectsAnDocuments.subscribe(
            (projectsAndDocs) => {
                this.projectsAndDocs = projectsAndDocs;
            }
        );

        this.templateFacade.treeDocument.subscribe(sectionsTree => {
            
            this.tree = sectionsTree;
        });
        this.templateFacade.optionsInSection.subscribe(_ => {
            // Allow animation to run for 1,5 secs so we have UI feedback for the saving
            setTimeout(() => this.isSaving = false, 1500);
        })
    }

    getProjects(): Project[] {
        return this.projectsAndDocs && this.projectsAndDocs.length > 0 ? this.projectsAndDocs.map(e => e.project) : []
    }

    getDocuments(): Document[] {
        return this.projectsAndDocs && this.selectedProject ? this.projectsAndDocs
            .find(s => s.project.projectName === this.selectedProject.projectName)
            .documents : [];
    }

    selectProject(project: Project) {
        if (!project) { return; }
        this.selectedProject = project;
        this.selectedDocument = null;
        this.selectedSection = null;
        this.selectedOption = null;
    }

    selectDocument(document: Document) {
        if (!document) { return; }
        this.selectedDocument = document;
        this.selectedSection = null;
        this.selectedOption = null;
        this.templateFacade.loadTree(this.selectedProject.projectId, document.documentId);
    }

    selectedItemFromTree(selection: { sectionId: number; optionId: number },) {
        if (selection.sectionId) {
            this.selectedSection = this.tree.find(s => s.sectionId == selection.sectionId);
            this.selectedOption = null;
            this.templateFacade.loadOptionsFromTree(selection.sectionId, this.selectedSection.options)
        }
        if (selection.optionId && this.selectedSection) {
            this.selectedOption = this.selectedSection.options.find(s => s.optionId == selection.optionId);
        }
    }


    saveOptionTemplate(option: any) {
        this.templateFacade.modifyOption(
            this.selectedProject.projectId,
            this.selectedDocument.documentId,
            this.selectedSection.sectionId,
            { ...this.selectedOption, optionTemplate: option });
    }

    createForm() {
        this.formCreateResource = new FormGroup({
            name: new FormControl('', Validators.required),
            order: new FormControl(''),
        });
        this.formDeleteResource = new FormGroup({
            resource: new FormControl('', Validators.required),
        });
        this.formEditResource = new FormGroup({
            resource: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            order: new FormControl('', Validators.pattern(/^-?(0|[1-9]\d*)?$/)),
        });
    }

    openModalForCreate(tab: 'area' | 'document' | 'section' | 'option') {
        this.activeTab = tab;
        this.changeDetection.detectChanges();
        this.isModalForCreateOpen = true;
    }

    openModalForDeletion(tab: 'area' | 'document' | 'section' | 'option') {
        this.activeTab = tab;
        this.changeDetection.detectChanges();
        this.isModalForDeletionOpen = true;
    }

    openModalForEdition(tab: 'area' | 'document' | 'section' | 'option') {
        this.activeTab = tab;
        this.changeDetection.detectChanges();
        this.isModalForEditionOpen = true;
    }

    closeModals() {
        this.isModalForCreateOpen = false;
        this.isModalForDeletionOpen = false;
        this.isModalForEditionOpen = false;
        this.formCreateResource.reset();
        this.formDeleteResource.reset();
        this.formEditResource.reset();

    }

    submitCreation() {
        let name = this.formCreateResource.value.name;
        switch (this.activeTab) {
            case 'area':
                this.templateFacade.createProject({ projectName: name } as Project)
                break;
            case 'document':
                this.templateFacade.createDocument(this.selectedProject.projectId, { documentName: name } as Document)
                break;
            case 'section':
                let order = this.formCreateResource.value.order;
                this.templateFacade.createSection(this.selectedProject.projectId, this.selectedDocument.documentId, { sectionName: name, sectionOrder: order } as Section)
                break;
            case 'option':
                this.templateFacade.createOption(this.selectedProject.projectId, this.selectedDocument.documentId, this.selectedSection.sectionId, { optionName: name } as Option)
                break;
            default:
                break;
        }
        this.closeModals();
    }

    submitDeletion() {
        let resource = this.formDeleteResource.value.resource;
        switch (this.activeTab) {
            case 'area':
                this.templateFacade.deleteProject((resource as Project).projectId)
                this.selectedProject = null;
                this.selectedDocument = null;
                this.selectedSection = null;
                this.selectedOption = null;
                break;
            case 'document':
                this.templateFacade.deleteDocument(this.selectedProject.projectId, (resource as Document).documentId)
                this.selectedDocument = null;
                this.selectedSection = null;
                this.selectedOption = null;
                break;
            case 'section':
                this.templateFacade.deleteSection(this.selectedProject.projectId, this.selectedDocument.documentId, (resource as Section).sectionId)
                this.selectedSection = null;
                this.selectedOption = null;
                break;
            case 'option':
                this.templateFacade.deleteOption(this.selectedProject.projectId, this.selectedDocument.documentId, this.selectedSection.sectionId, (resource as Option).optionId)
                this.selectedOption = null;
                break;
            default:
                break;
        }
        this.closeModals();
    }

    submitEdition() {
        let resource = this.formEditResource.value.resource;
        let name = this.formEditResource.value.name ? this.formEditResource.value.name : resource[this.getChoiceSelector()];
        switch (this.activeTab) {
            case 'area':
                this.templateFacade.modifyProject(({ ...resource, projectName: name } as Project))
                this.selectedProject = null;
                this.selectedDocument = null;
                this.selectedSection = null;
                this.selectedOption = null;
                break;
            case 'document':
                this.templateFacade.modifyDocument(this.selectedProject.projectId, ({ ...resource, documentName: name } as Document))
                this.selectedDocument = null;
                this.selectedSection = null;
                this.selectedOption = null;
                break;
            case 'section':
                name = this.formEditResource.value.name ? this.formEditResource.value.name : resource.sectionName;
                let order = this.formEditResource.value.order ? this.formEditResource.value.order : resource.sectionOrder;
                this.templateFacade.modifySection(this.selectedProject.projectId, this.selectedDocument.documentId, ({ ...resource, sectionName: name, sectionOrder: order } as Section))
                this.selectedSection = null;
                this.selectedOption = null;
                break;
            case 'option':
                this.templateFacade.modifyOption(this.selectedProject.projectId, this.selectedDocument.documentId, this.selectedSection.sectionId, ({ ...resource, optionName: name } as Option))
                this.selectedOption = null;
                break;
            default:
                break;
        }
        this.closeModals();
    }

    getChoices() {
        switch (this.activeTab) {
            case 'area':
                return this.getProjects();
            case 'document':
                return this.getDocuments();
            case 'section':
                return this.tree ? this.tree : []
            case 'option':
                if(!this.tree || this.tree.length == 0 ) {
                    return []
                }
                let optionalSection = this.tree.find(t => t.sectionId == this.selectedSection.sectionId);
                if(optionalSection) {
                    return optionalSection.options;
                } else {
                    return []
                }
            default:
                return [];
        }
    }

    getChoiceSelector() {
        switch (this.activeTab) {
            case 'area':
                return 'projectName';
            case 'document':
                return 'documentName';
            case 'section':
                return 'sectionOrder+sectionName';
            case 'option':
                return 'optionName';
            default:
                return 'name';
        }
    }

}