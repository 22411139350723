import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserFacade } from '@services/user/user.facade';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginError: boolean;
  form: FormGroup;
  hidePassword : boolean = true;

  constructor(private userfacade: UserFacade, private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  public login() {
    this.userfacade.loginUser(this.form.value.username, this.form.value.password).subscribe(
      (res) => {this.loginError = false; this.router.navigate(['/']);},
      (error) => {
        console.log("login error subscription",)
        this.loginError = true;
      }
    );
  }

  public toggleRevealPassword() {
    this.hidePassword = !this.hidePassword;
  }
  
  private createForm() {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }
}
