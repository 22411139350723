<div class="plan-section">
 <h1 class="plan-section-header" translate>{{'plans.title'}}</h1>

 <div class="plan-section-selector">
    <div *ngFor="let interval of intervals" 
        class="plan-section-selector-item selected"
        [ngClass]="{'selected' : selectedInterval === interval}"
        (click)="setSelectedInterval(interval)">
        <p>{{interval}}</p>
    </div>
 </div>

 <div class="plan-section-options">
    <div  *ngFor="let plan of selectedPlans" class="plan-section-option">
        <div class="plan-section-option-icon">
            <i [ngClass]="plan.icon"></i>
        </div>
        <p class="plan-section-option-name">{{plan.name}}</p>
        <div class="plan-section-option-description">
            <p>{{plan.description}}</p>
            <p>{{'plans.numberOfSubscribers' | translate}}: {{plan.numberSubscribers}}</p>
        </div>
        <div class="plan-section-option-footer">
            <div><p class="plan-section-option-price">{{plan.price}}€</p></div>
            <button 
                class="btn is-primary plan-section-option-buy-button"
                (click)="subscribeToPlan(plan)" translate>
                {{'plans.orderNow'}}</button>
        </div>
    </div>
 </div>

 <div class="plan-section-get-invited">
    <p translate>{{'plans.gettingInvitedHint'}}</p>
    <p style="text-align: center; color: white;"><strong>{{myUserId}}</strong></p>
    <div class="mt-3 footer-floating">
        <div>
            <p class="" translate>{{'plans.issueHint'}} <a class="refresh" [routerLink]="['/']" translate>{{'plans.issueHintLink'}}</a></p>
        </div>
        <div *ngIf="subscriptions">
            <h3 class="" translate>{{'plans.currentSubscriptions'}}</h3>
            <ol>
                <li *ngFor="let subscription of subscriptions" class="subscriptions is-flex">
                    <i [ngClass]="subscription.status == 'ACTIVE'? 'ri-check-double-fill' : 'ri-error-warning-fill'" [attr.title]="subscription.status">
                        <span class="help tooltiptext">{{subscription.status}}</span>
                    </i>
                    <p class="help">{{subscription.plan.name}}</p>
                </li>
            </ol>
        </div>
     </div>
</div>

</div>
