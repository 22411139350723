<div class="template-editor-section">
    <div class="navigation pl-5">
        <h1 style="padding:0px" translate>{{'manageTemplates.title'}}</h1>
        
        <div class="box">
            <div class="columns">
                <div class="column">
                    <div class="selector">
                        <div class="item">
                            <div class="icon-text">
                                <div class="image is-36x36 mr-3">
                                    <img style="min-width: 24px" src="assets/icons/project-icon.svg"/>
                                </div>
                                <div translate>{{'templates.area'}}</div>
                                <div class="ml-3 is-flex">
                                    <a class="image is-24x24" (click)="openModalForCreate('area')">
                                        <img src="assets/icons/file-add-icon.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForEdition('area')">
                                        <img src="assets/icons/edit-icon-fill.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForDeletion('area')">
                                        <img src="assets/icons/trash-doc-icon.svg"/>
                                    </a>
                                </div>
                            </div>
                            <app-autocomplete-input
                                *ngIf="projectsAndDocs && projectsAndDocs.length > 0"
                                class="dark-search-bar"
                                [options]="getProjects()"
                                (optionClicked)="selectProject($event)"
                                [placeholder]="'form.noElementsInSearch'"
                                [notFoundPlaceholder]="'No se ha encontrado elementos'"
                                [selector]="'projectName'"
                                [isFormControl]="false"
                            ></app-autocomplete-input>
                        </div>
                        <div class="item">
                            <div class="icon-text">
                                <div class="image is-36x36 mr-3">
                                    <img style="min-width: 24px" src="assets/icons/document-file.svg"/>
                                </div>
                                <div translate>{{'templates.document'}}</div>
                                <div *ngIf="selectedProject" class="ml-3 is-flex">
                                    <a class="image is-24x24" (click)="openModalForCreate('document')">
                                        <img src="assets/icons/file-add-icon.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForEdition('document')">
                                        <img src="assets/icons/edit-icon-fill.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForDeletion('document')">
                                        <img src="assets/icons/trash-doc-icon.svg"/>
                                    </a>
                                </div>
                            </div>
                            <p *ngIf="!selectedProject" class="hint">{{'manageTemplates.manageDocumentsHint' | translate}}</p>
                            <app-autocomplete-input
                                *ngIf="projectsAndDocs && projectsAndDocs.length > 0 && selectedProject"
                                class="dark-search-bar"
                                [options]="getDocuments()"
                                (optionClicked)="selectDocument($event)"
                                [placeholder]="'form.noElementsInSearch'"
                                [notFoundPlaceholder]="'No se ha encontrado elementos'"
                                [selector]="'documentName'"
                                [isFormControl]="false"
                            ></app-autocomplete-input>
                        </div>
                        <div class="item">
                            <div class="icon-text">
                                <div class="image is-36x36 mr-3">
                                    <img style="min-width: 24px" src="assets/icons/section-icon.svg"/>
                                </div>
                                <div translate>{{'templates.section'}}</div>
                                <div *ngIf="selectedDocument" class="ml-3 is-flex">
                                    <a class="image is-24x24" (click)="openModalForCreate('section')" >
                                        <img src="assets/icons/file-add-icon.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForEdition('section')">
                                        <img src="assets/icons/edit-icon-fill.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForDeletion('section')">
                                        <img src="assets/icons/trash-doc-icon.svg"/>
                                    </a>
                                </div>
                            </div>
                            <p *ngIf="!selectedDocument" class="hint">{{'manageTemplates.manageSectionsHint'  | translate}}</p>
                            <p *ngIf="selectedSection" class="dark-search-bar ">{{selectedSection.sectionName}}</p>
                        </div>
                        <div class="item">
                            <div class="icon-text">
                                <div class="image is-36x36 mr-3">
                                    <img style="min-width: 24px" src="assets/icons/option-icon.svg"/>
                                </div>
                                <div translate>{{'templates.option'}}</div>
                                <div *ngIf="selectedSection" class="ml-3 is-flex">
                                    <a class="image is-24x24" (click)="openModalForCreate('option')">
                                        <img src="assets/icons/file-add-icon.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForEdition('option')">
                                        <img src="assets/icons/edit-icon-fill.svg"/>
                                    </a>
                                    <a class="image is-24x24" (click)="openModalForDeletion('option')">
                                        <img src="assets/icons/trash-doc-icon.svg"/>
                                    </a>
                                </div>
                            </div>
                            <p *ngIf="!selectedSection" class="hint">{{'manageTemplates.manageOptionnsHint'  | translate}}</p>
                            <p *ngIf="selectedOption" class="dark-search-bar ">{{selectedOption.optionName}}</p>
                        </div>                
                    </div>
                    <project-tree
                        class="project-tree"
                        [document]="selectedDocument" 
                        [tree]="tree"
                        (itemClicked)="selectedItemFromTree($event)">
                    </project-tree>
                </div>
                <div class="column is-4 dark-box">
                    <p [innerHtml]="'manageTemplates.generalInstructions' | translate"></p>
                </div>
            </div>
            <div *ngIf="selectedOption">
                <tiptap-editor
                    [initialContent]="selectedOption.optionTemplate"
                    [isSaving]="isSaving"
                    [hasPlaceholderButton]="true"
                    (saveContent)="saveOptionTemplate($event)"
                ></tiptap-editor>
            </div>
        </div>    
    </div>

    <modal 
        [showModal]="isModalForCreateOpen" 
        [title]="('manageTemplates.add' | translate) + ' - ' +(('templates.'+ activeTab) | translate)"
        (modalClosed)="closeModals()">

        <form form [formGroup]="formCreateResource" (submit)="submitCreation()">
            <div class="field">
                <label class="label" translate>{{'manageTemplates.name'}}</label>
                <div class="control">
                    <input formControlName="name" class="input" type="text" placeholder="{{'manageTemplates.name' | translate}}">
                </div>
            </div>
            <div *ngIf="activeTab == 'section'" class="field" >
                <div class="control">
                    <label class="label" translate>{{'templates.sectionOrder'}}</label>
                    <input formControlName="order" class="input" type="text" placeholder="1.2.3" >
                </div>
            </div>
            <button 
                [disabled]="formCreateResource && formCreateResource.invalid" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                translate
            >{{'form.continue'}}</button>
        </form>
    </modal>

    <modal 
        [showModal]="isModalForDeletionOpen" 
        [title]="('form.delete' | translate) + ' - '+(('templates.'+ activeTab) | translate)"
        (modalClosed)="closeModals()">

        <form form [formGroup]="formDeleteResource" (submit)="submitDeletion()">
            <div class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <app-autocomplete-input
                        class="dark-search-bar"
                        [options]="getChoices()"
                        [placeholder]="'form.search'"
                        [notFoundPlaceholder]="'form.noElementsInSearch'"
                        [selector]="getChoiceSelector()"
                        [isFormControl]="true"
                        formControlName="resource"
                    ></app-autocomplete-input>
                </div>
            </div>
            <button 
                [disabled]="formDeleteResource && formDeleteResource.invalid" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                translate
            >{{'form.delete'}}</button>
        </form>
    </modal>

    <modal 
        [showModal]="isModalForEditionOpen" 
        [title]="('manageTemplates.edit' | translate) + ' - '+(('templates.'+ activeTab) | translate)"
        (modalClosed)="closeModals()">

        <form form [formGroup]="formEditResource" (submit)="submitEdition()">
            <div class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <app-autocomplete-input
                        class="dark-search-bar"
                        [options]="getChoices()"
                        [placeholder]="'form.search'"
                        [notFoundPlaceholder]="'form.noElementsInSearch'"
                        [selector]="getChoiceSelector()"
                        [isFormControl]="true"
                        formControlName="resource"
                    ></app-autocomplete-input>
                </div>
            </div>
            <div *ngIf="activeTab !== 'section'" class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <input class="input"  type="text"  formControlName="name"
                            [value]="formEditResource.controls.resource?.value !== null ? formEditResource.controls.resource?.value[getChoiceSelector()] && formEditResource.controls.resource?.value[getChoiceSelector()]: ''"
                            [attr.disabled]="formEditResource.controls.resource?.invalid ? '' : null" >
                </div>
            </div> 
            <div *ngIf="activeTab == 'section'" class="field">                
                <div class="control">
                    <label class="label" translate>{{'manageTemplates.name'}}</label>
                    <input class="input"  type="text" formControlName="name"
                            [value]="formEditResource.controls.resource?.value !== null ? formEditResource.controls.resource?.value.sectionName : ''"
                            [attr.disabled]="formEditResource.controls.resource?.invalid ? '' : null" >
                </div>
            </div> 
            <div *ngIf="activeTab == 'section'" class="field">                
                <div class="control">
                    <label class="label" translate>{{'templates.sectionOrder'}}</label>
                    <input class="input"  type="text" formControlName="order" 
                            [value]="formEditResource.controls.resource?.value !== null ? formEditResource.controls.resource?.value.sectionOrder: ''"
                            [attr.disabled]="formEditResource.controls.resource?.invalid ? '' : null">
                </div>
            </div>
            <button 
                [disabled]="formEditResource && formEditResource.invalid && !formEditResource.controls.order?.dirty && !formEditResource.controls.order?.touched" 
                class="button is-primary is-rounded is-pulled-right" 
                type="submit"
                translate
            >{{'form.continue'}}</button>
        </form>

    </modal>

</div>
