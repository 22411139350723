import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { TemplateService } from '@services/templates/templates.service';
import { CreateDocument, CreateDocumentError, CreateDocumentFetched, CreateOption, CreateOptionError, CreateOptionFetched, CreateProject, CreateProjectError, CreateProjectFetched, CreateSection, CreateSectionError, CreateSectionFetched, CreateUserDocument, CreateUserDocumentError, CreateUserDocumentSuccess, DeleteDocument, DeleteDocumentDone, DeleteDocumentError, DeleteOption, DeleteOptionDone, DeleteOptionError, DeleteProject, DeleteProjectDone, DeleteProjectError, DeleteSection, DeleteSectionDone, DeleteSectionError, DeleteUserDocument, DeleteUserDocumentError, DeleteUserDocumentSuccess, LoadOptionsInSection, LoadOptionsInSectionError, LoadOptionsInSectionFetched, LoadProjectsAndDocuments, LoadProjectsAndDocumentsError, LoadProjectsAndDocumentsFetched, LoadRecentDocument, LoadRecentDocumentError, LoadRecentDocumentFetched, LoadRecentDocuments, LoadRecentDocumentsError, LoadRecentDocumentsFetched, LoadSectionsInDocument, LoadSectionsInDocumentError, LoadSectionsInDocumentFetched, LoadTreeDocument, LoadTreeDocumentError, LoadTreeDocumentFetched, ModifyUserDocument, ModifyUserDocumentError, ModifyUserDocumentSuccess, PatchDocument, PatchDocumentError, PatchDocumentFetched, PatchOption, PatchOptionError, PatchOptionFetched, PatchProject, PatchProjectError, PatchProjectFetched, PatchSection, PatchSectionError, PatchSectionFetched, TemplatesActionsType } from './templates.actions';

@Injectable()
export class TemplatesEffects {
  
  @Effect()
  loadProjectsAndDocuments = this.actions.pipe(
    ofType(TemplatesActionsType.LoadProjectsAndDocumentsType),
    mergeMap((action: LoadProjectsAndDocuments) =>
      this.templateService.getProjectDocuments().pipe(
        map((res) => new LoadProjectsAndDocumentsFetched(res.projectAndDocumentsList)),
        catchError((err) => of(new LoadProjectsAndDocumentsError()))
      )
    )
  );

  @Effect()
  loadTreeInDocument = this.actions.pipe(
    ofType(TemplatesActionsType.LoadTreeType),
    mergeMap((action: LoadTreeDocument) =>
      this.templateService.loadTree(action.documentId).pipe(
        map((res) => new LoadTreeDocumentFetched(action.projectId, action.documentId, res.sections)),
        catchError((err) => of(new LoadTreeDocumentError()))
      )
    )
  );

  @Effect()
  loadSectionsInDocument = this.actions.pipe(
    ofType(TemplatesActionsType.LoadSectionsInDocumentType),
    mergeMap((action: LoadSectionsInDocument) =>
      this.templateService.loadSections(action.documentId).pipe(
        map((res) => new LoadSectionsInDocumentFetched(action.projectId, action.documentId, res.sections)),
        catchError((err) => of(new LoadSectionsInDocumentError()))
      )
    )
  );

  @Effect()
  loadOptionsInSection = this.actions.pipe(
    ofType(TemplatesActionsType.LoadOptionsInSectionType),
    mergeMap((action: LoadOptionsInSection) =>
      this.templateService.loadOptions(action.documentId, action.sectionId).pipe(
        map((res) => new LoadOptionsInSectionFetched(action.sectionId, res.options)),
        catchError((err) => of(new LoadOptionsInSectionError()))
      )
    )
  );

  @Effect()
  loadRecentDocuments = this.actions.pipe(
    ofType(TemplatesActionsType.LoadRecentDocumentsType),
    mergeMap((action: LoadRecentDocuments) =>
      this.templateService.loadRecentDocuments(action.userId).pipe(
        map((res) => new LoadRecentDocumentsFetched(res.recentDocuments)),
        catchError((err) => of(new LoadRecentDocumentsError()))
      )
    )
  );

  @Effect()
  loadRecentDocument = this.actions.pipe(
    ofType(TemplatesActionsType.LoadRecentDocumentType),
    mergeMap((action: LoadRecentDocument) =>
      this.templateService.fetchRecentDocument(action.userId, action.userDocumentId).pipe(
        map((res) => new LoadRecentDocumentFetched(res)),
        catchError((err) => of(new LoadRecentDocumentError()))
      )
    )
  );

  @Effect()
  createUserDocuments = this.actions.pipe(
    ofType(TemplatesActionsType.CreateUserDocumentType),
    mergeMap((action: CreateUserDocument) =>
      this.templateService.createUserDocuments(action.userId, action.userDocument).pipe(
        map((res) => new CreateUserDocumentSuccess(res)),
        catchError((err) => of(new CreateUserDocumentError()))
      )
    )
  );

  @Effect()
  MmdifyUserDocuments = this.actions.pipe(
    ofType(TemplatesActionsType.ModifyUserDocumentType),
    mergeMap((action: ModifyUserDocument) =>
      this.templateService.modifyUserDocuments(action.userId, action.userDocument).pipe(
        map((res) => new ModifyUserDocumentSuccess(res)),
        catchError((err) => of(new ModifyUserDocumentError()))
      )
    )
  );

  @Effect()
  deleteUserDocuments = this.actions.pipe(
    ofType(TemplatesActionsType.DeleteUserDocumentType),
    mergeMap((action: DeleteUserDocument) =>
      this.templateService.deleteUserDocuments(action.userId, action.userDocumentId).pipe(
        map(() => new DeleteUserDocumentSuccess(action.userDocumentId)),
        catchError((err) => of(new DeleteUserDocumentError()))
      )
    )
  );

  // Creates
  @Effect()
  createProject = this.actions.pipe(
    ofType(TemplatesActionsType.CreateProjectType),
    mergeMap((action: CreateProject) =>
      this.templateService.createProject(action.request).pipe(
        map((res) => new CreateProjectFetched(res)),
        catchError((err) => of(new CreateProjectError()))
      )
    )
  );


  @Effect()
  createDocument = this.actions.pipe(
    ofType(TemplatesActionsType.CreateDocumentType),
    mergeMap((action: CreateDocument) =>
      this.templateService.createDocument(
                                    action.projectId, 
                                    action.request).pipe(
        map((res) => new CreateDocumentFetched(res, action.projectId)),
        catchError((err) => of(new CreateDocumentError()))
      )
    )
  );

  @Effect()
  createSection = this.actions.pipe(
    ofType(TemplatesActionsType.CreateSectionType),
    mergeMap((action: CreateSection) =>
      this.templateService.createSection(
                                    action.projectId, 
                                    action.documentId,
                                    action.request).pipe(
        map((res) => new CreateSectionFetched(res, action.documentId)),
        catchError((err) => of(new CreateSectionError()))
      )
    )
  );

  @Effect()
  createOption = this.actions.pipe(
    ofType(TemplatesActionsType.CreateOptionType),
    mergeMap((action: CreateOption) =>
      this.templateService.createOption(
                                    action.projectId, 
                                    action.documentId,
                                    action.sectionId,
                                    action.request).pipe(
        map((res) => new CreateOptionFetched(res, action.sectionId)),
        catchError((err) => of(new CreateOptionError()))
      )
    )
  );

  // Modifies
  @Effect()
  patchProject = this.actions.pipe(
    ofType(TemplatesActionsType.PatchProjectType),
    mergeMap((action: PatchProject) =>
      this.templateService.modifyProject(action.patch).pipe(
        map((res) => new PatchProjectFetched(res)),
        catchError((err) => of(new PatchProjectError()))
      )
    )
  );


  @Effect()
  patchDocument = this.actions.pipe(
    ofType(TemplatesActionsType.PatchDocumentType),
    mergeMap((action: PatchDocument) =>
      this.templateService.modifyDocument(
                                    action.projectId, 
                                    action.request).pipe(
        map((res) => new PatchDocumentFetched(res)),
        catchError((err) => of(new PatchDocumentError()))
      )
    )
  );

  @Effect()
  patchSection = this.actions.pipe(
    ofType(TemplatesActionsType.PatchSectionType),
    mergeMap((action: PatchSection) =>
      this.templateService.modifySection(
                                    action.projectId, 
                                    action.documentId,
                                    action.request).pipe(
        map((res) => new PatchSectionFetched(res)),
        catchError((err) => of(new PatchSectionError()))
      )
    )
  );

  @Effect()
  patchOption = this.actions.pipe(
    ofType(TemplatesActionsType.PatchOptionType),
    mergeMap((action: PatchOption) =>
      this.templateService.modifyOption(
                                    action.projectId, 
                                    action.documentId,
                                    action.sectionId,
                                    action.patch).pipe(
        map((res) => new PatchOptionFetched(res)),
        catchError((err) => of(new PatchOptionError()))
      )
    )
  );

  // Deletes
  
  @Effect()
  deleteProject = this.actions.pipe(
    ofType(TemplatesActionsType.DeleteProjectType),
    mergeMap((action: DeleteProject) =>
      this.templateService.deleteProject(action.projectId).pipe(
        map((res) => new DeleteProjectDone(action.projectId)),
        catchError((err) => of(new DeleteProjectError()))
      )
    )
  );


  @Effect()
  deleteDocument = this.actions.pipe(
    ofType(TemplatesActionsType.DeleteDocumentType),
    mergeMap((action: DeleteDocument) =>
      this.templateService.deleteDocument(
                                    action.projectId, 
                                    action.documentId).pipe(
        map((res) => new DeleteDocumentDone(action.projectId, action.documentId)),
        catchError((err) => of(new DeleteDocumentError()))
      )
    )
  );

  @Effect()
  deleteSection = this.actions.pipe(
    ofType(TemplatesActionsType.DeleteSectionType),
    mergeMap((action: DeleteSection) =>
      this.templateService.deleteSection(
                                    action.projectId, 
                                    action.documentId,
                                    action.sectionId).pipe(
        map((res) => new DeleteSectionDone(action.sectionId)),
        catchError((err) => of(new DeleteSectionError()))
      )
    )
  );

  @Effect()
  deleteOption = this.actions.pipe(
    ofType(TemplatesActionsType.DeleteOptionType),
    mergeMap((action: DeleteOption) =>
      this.templateService.deleteOption(
                                    action.projectId, 
                                    action.documentId,
                                    action.sectionId,
                                    action.optionId).pipe(
        map((res) => new DeleteOptionDone(action.sectionId, action.optionId)),
        catchError((err) => of(new DeleteOptionError()))
      )
    )
  );
  // Deletes
  
  constructor(private actions: Actions, private readonly templateService: TemplateService) {}
}
