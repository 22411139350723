import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { from } from 'rxjs';

import { UserEffects } from '@services/user/user.effects';
import * as fromUser from '@services/user/user.reducer';
import * as fromTemplates from '@services/templates/templates.reducer';
import { StateActionsType } from './state.actions';
import { TemplatesEffects } from '@services/templates/templates.effects';

interface AppState {
  user: fromUser.UserState;
  templates: fromTemplates.TemplateState;
}

/**
 * User Selectors
 */
export const selectUserState = createFeatureSelector('user');
export const getCurrentUser = createSelector(selectUserState, fromUser.getCurrentUser);

/**
 * Templates Selectors
 */

export const selectTemplateState = createFeatureSelector('templates');
export const getProjectsAndDocuments = createSelector(selectTemplateState, fromTemplates.getProjectsAndDocuments);
export const treeDocument = createSelector(selectTemplateState, fromTemplates.treeDocument);
export const sectionsInDocument = createSelector(selectTemplateState, fromTemplates.sectionsInDocument);
export const optionsInSection = createSelector(selectTemplateState, fromTemplates.optionsInSection);
export const recentDocuments = createSelector(selectTemplateState, fromTemplates.recentDocuments);

export const userDocument = createSelector(selectTemplateState, fromTemplates.userDocument);

/**
 * Reducers List
 */

export const reducers: ActionReducerMap<AppState> = {
  user: fromUser.userReducer,
  templates: fromTemplates.templatesReducer
};

/**
 * META REDUCERS
 */
export const resetStore = (reducer: ActionReducer<any>): ActionReducer<any> => (state: AppState, action: Action) => {
  if (action.type === StateActionsType.ResetStoreType) {
    state = undefined;
  }
  return reducer(state, action);
};

export const metaReducers: MetaReducer<any>[] = [resetStore];

/**
 * Effects List
 */
export const effects: any[] = [UserEffects, TemplatesEffects];
