import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, Observable, of } from 'rxjs';
import { first, map, skip } from 'rxjs/operators';

/**
 * A base class for common behaviour for ngrx facades
 */
export abstract class StateFacade {
  private readonly actionDelay = 1;

  constructor(public actions: Actions, public store: Store<any>) {}

  /**
   * Subscribes to two actions. The first one will indicate success, and if it has a payload it will be returned.
   * The second one will indicate an error.
   * Useful for getting the result of an action
   * @param successActionType An action indicating succes
   * @param errorActionType An action indicating error
   */
  protected getActionResult(successActionType: string, errorActionType: string): Observable<any> {
    
    return this.actions.pipe(
      ofType(successActionType, errorActionType),
      map((action: Action) => {
    
        if (action.type !== successActionType) {
          throw new Error();
        }
        return action['payload'] ? action['payload'] : undefined;
      })
    );
  }

  /**
   * Merges an action and an action result observable. Useful for getting the result of an action
   * @param action The action to dispatch
   * @param successObservable The observable that will track de success or error
   */
  protected observableAction(action: Action, successObservable: Observable<any>) {
    // TODO: try to eliminate setTimeOut
    return merge(successObservable, of(setTimeout(() => this.store.dispatch(action), this.actionDelay))).pipe(
      skip(1),
      first()
    );
  }

  /**
   * Dispatch the given action and returns its result
   * @param action The action to dispatch
   * @param successActionType An action indicating succes
   * @param errorActionType An action indicating error
   */
  protected dispatchActionAndGetResult(action: Action, successActionType: string, errorActionType: string) {
    return this.observableAction(action, this.getActionResult(successActionType, errorActionType));
  }
}
